import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { sanitize } from 'dompurify';

import { ShowMarketplaceError } from '../../utils/core/coreutil';
import ScheduleAPI from '../../api/schedule/scheduleapi';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import { FormatScheduleDate } from '../../utils/library/libraryutil';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import MarketplaceSpinner from '../../components/shared/marketplacespinner/marketplacespinner';
import { isMobile } from 'react-device-detect';

const ScheduleAvailability = (props) => {
    const location = useLocation()
    const marketplaceSessionGUID = useSelector((state) => state.core.marketplaceSessionGUID);
    const agentProfileGUID = useSelector((state) => state.core.agentProfileGUID);
    const agentProfileID = useSelector((state) => state.core.agentProfileID);
    const [data, setData] = useState({
        marketplaceSessionGUID: marketplaceSessionGUID,
        agentProfileGUID: agentProfileGUID,
        agentProfileID: agentProfileID,
        existingSourcingScheduleID: location?.state?.existingSourcingScheduleID ? location?.state?.existingSourcingScheduleID : 0,
        scheduleInterval: '',
        startDate: '',
        endDate: '',
        priorWeek: '',
        nextWeek: '',
        timeZone: '',
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [timesAvailable, setTimesAvailable] = useState(true);
    const [formHeader, setFormHeader] = useState('');
    const [dateRange, setDateRange] = useState('10/27/2019 - 11/02/2019');

    useEffect(() => {
        scheduleAvailabilityOnLoad();
    }, [reload]);

    const scheduleAvailabilityOnLoad = () => {

        setLoading(true);
        ScheduleAPI.ScheduleAvailability(data).then((response) => {

            let scheduleAvailabilityStatus = response;

            switch (scheduleAvailabilityStatus.responseStatus.toLowerCase()) {
                case 'no opportunities':
                    setFormHeader('Thank you for completing the Marketplace Matching experience!  While there is not a perfect match for you right now, new opportunities are registered in the Marketplace all the time.');
                    break;
                case 'success':
                    setFormHeader('Click an available time below and schedule a call with a Marketplace Coordinator to learn more.<b> Please be sure to select your time zone!</b>');
                    setData(values => {
                        return {
                            ...values,
                            startDate: scheduleAvailabilityStatus.startDate,
                            endDate: scheduleAvailabilityStatus.endDate,
                            priorWeek: scheduleAvailabilityStatus.priorWeek,
                            nextWeek: scheduleAvailabilityStatus.nextWeek,
                            timeZone: scheduleAvailabilityStatus.timeZone
                        }
                    });

                    setDateRange(scheduleAvailabilityStatus.startDate + ' - ' + scheduleAvailabilityStatus.endDate);

                    var d = new Date(scheduleAvailabilityStatus.startDate);

                    for (var i = 0; i < 7; i++) {
                        var newDate = new Date(d);
                        newDate.setDate(newDate.getDate() + i);
                        const targetDiv = document.getElementById('dayWideDate-' + i.toString());
                        targetDiv.textContent = FormatScheduleDate(newDate, 2);
                    }

                    if (scheduleAvailabilityStatus.intervalCount === 0) {
                        setTimesAvailable(false);
                        toast.error('No available times, please select a different week.');
                    }
                    else {
                        setTimesAvailable(true);
                        scheduleAvailabilityStatus.intervals.map((item) => {
                            const targetDiv = document.getElementById('interval-' + item.dailyInterval);
                            if (item.intervalStatus === 'Available')
                                targetDiv.className = 'marketplaceIntervalAvailable';
                            else if (item.intervalStatus === 'Unavailable')
                                targetDiv.className = 'marketplaceIntervalUnavailable';
                            else
                                targetDiv.className = 'marketplaceIntervalHidden';

                        });
                    }
                    break;
                default:
                    toast.error(scheduleAvailabilityStatus);

            }
            setLoading(false);
        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0);
            }
            else
                ShowMarketplaceError(e.message, 'scheduleavailability.js', 'scheduleAvailabilityOnLoad()');
        });
    };

    const handleInputChange = (e) => {
        e.preventDefault();

        setData(prevState => {
            return { ...prevState, [e.target.name]: e.target.value }
        });

        if (e.target.name === 'timeZone')
            setReload(!reload);
    };

    const onIntervalClick = (e) => {
        try {

            let targetDiv = document.getElementById(e.target.id);
            let hasClass = targetDiv.classList.contains('marketplaceIntervalAvailable');

            if (!hasClass)
                return;

            var actionSplit = e.target.id.split('-');

            var action = actionSplit[0];
            var actionDetails = '';

            if (action.length > 1)
                actionDetails = actionSplit[1];

            navigate('/scheduleconfirm', { state: { startDate: data.startDate, timeZone: data.timeZone, scheduleInterval: actionDetails, existingSourcingScheduleID: data.existingSourcingScheduleID } })

        } catch (e) {
            toast.error(e);
        }
    };

    const onCancelClick = () => {
        navigate('/gateway')
    };

    const onWeekChange = (startDay) => {

        setData(values => {
            return {
                ...values,
                startDate: startDay,
            }
        });

        setReload(!reload);

    };

    return (
        <>
            <div hidden={loading} className='marketplaceFormContainer'>
                <form className='marketplaceForm' style={loading || !timesAvailable ? { height: '350px' } : { height: '750px' }}>
                    <div className='marketplaceFormContent'>
                        <h3 className='marketplaceFormTitle'>NexRep Contractor Marketplace</h3>
                        <p dangerouslySetInnerHTML={{ __html: sanitize(formHeader) }}></p>
                        <div >
                            <div className={!isMobile ? 'marketplaceInline50' : ''} >
                                <label className='marketplaceFormFieldLabel'>Time Zone</label>
                                <Select
                                    required
                                    fullWidth
                                    name='timeZone'
                                    defaultValue='Eastern'
                                    className='mt-1'
                                    value={data.timeZone}
                                    onChange={(e) => { handleInputChange(e) }}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 400,
                                        },
                                    }}
                                    size='small'>
                                    <MenuItem value='Eastern'>Eastern</MenuItem>
                                    <MenuItem value='Central'>Central</MenuItem>
                                    <MenuItem value='Mountain'>Mountain</MenuItem>
                                    <MenuItem value='Pacific'>Pacific</MenuItem>
                                </Select>
                            </div>
                            <div className={!isMobile ? 'marketplaceInline50' : 'marketplaceInline50 mobile'}>
                                <label className='marketplaceFormFieldLabel'>Change Week</label>
                                <span className='marketplaceScheduleOptionsWeekChange'>
                                    <span className='marketplaceScheduleOptionsWeekPrior' onClick={() => { onWeekChange(data.priorWeek) }}><ChevronLeft style={{ color: '#757575' }} /></span>
                                    <span className='marketplaceScheduleOptionsWeekDate'>{dateRange}</span>
                                    <span onClick={() => { onWeekChange(data.nextWeek) }} className='marketplaceScheduleOptionsWeekNext'><ChevronRight style={{ color: '#757575' }} /></span>
                                </span>
                                {/* <MarketplaceWeekPicker onChange={onWeekChange} /> */}
                            </div>
                            <div hidden={!timesAvailable} className='marketplaceScheduleWeek' id='intervals'>
                                <div className='marketplaceScheduleDay'>
                                    <div className='marketplaceScheduleDayWide'><div className='marketplaceScheduleDayWideDay'>SUN</div><div className='marketplaceScheduleDayWideDate' id='dayWideDate-0'>10/27/19</div></div>
                                    <div id='interval-00000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 AM</div>
                                    <div id='interval-00015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 AM</div>
                                    <div id='interval-00030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 AM</div>
                                    <div id='interval-00045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 AM</div>
                                    <div id='interval-00100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 AM</div>
                                    <div id='interval-00115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 AM</div>
                                    <div id='interval-00130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 AM</div>
                                    <div id='interval-00145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 AM</div>
                                    <div id='interval-00200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 AM</div>
                                    <div id='interval-00215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 AM</div>
                                    <div id='interval-00230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 AM</div>
                                    <div id='interval-00245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 AM</div>
                                    <div id='interval-00300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 AM</div>
                                    <div id='interval-00315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 AM</div>
                                    <div id='interval-00330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 AM</div>
                                    <div id='interval-00345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 AM</div>
                                    <div id='interval-00400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 AM</div>
                                    <div id='interval-00415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 AM</div>
                                    <div id='interval-00430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 AM</div>
                                    <div id='interval-00445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 AM</div>
                                    <div id='interval-00500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 AM</div>
                                    <div id='interval-00515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 AM</div>
                                    <div id='interval-00530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 AM</div>
                                    <div id='interval-00545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 AM</div>
                                    <div id='interval-00600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 AM</div>
                                    <div id='interval-00615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 AM</div>
                                    <div id='interval-00630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 AM</div>
                                    <div id='interval-00645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 AM</div>
                                    <div id='interval-00700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 AM</div>
                                    <div id='interval-00715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 AM</div>
                                    <div id='interval-00730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 AM</div>
                                    <div id='interval-00745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 AM</div>
                                    <div id='interval-00800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 AM</div>
                                    <div id='interval-00815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 AM</div>
                                    <div id='interval-00830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30AM</div>
                                    <div id='interval-00845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45AM</div>
                                    <div id='interval-00900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 AM</div>
                                    <div id='interval-00915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 AM</div>
                                    <div id='interval-00930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 AM</div>
                                    <div id='interval-00945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 AM</div>
                                    <div id='interval-01000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 AM</div>
                                    <div id='interval-01015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 AM</div>
                                    <div id='interval-01030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 AM</div>
                                    <div id='interval-01045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 AM</div>
                                    <div id='interval-01100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 AM</div>
                                    <div id='interval-01115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 AM</div>
                                    <div id='interval-01130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 AM</div>
                                    <div id='interval-01145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 AM</div>
                                    <div id='interval-01200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 PM</div>
                                    <div id='interval-01215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 PM</div>
                                    <div id='interval-01230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 PM</div>
                                    <div id='interval-01245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 PM</div>
                                    <div id='interval-01300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 PM</div>
                                    <div id='interval-01315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 PM</div>
                                    <div id='interval-01330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 PM</div>
                                    <div id='interval-01345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 PM</div>
                                    <div id='interval-01400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 PM</div>
                                    <div id='interval-01415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 PM</div>
                                    <div id='interval-01430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 PM</div>
                                    <div id='interval-01445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 PM</div>
                                    <div id='interval-01500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 PM</div>
                                    <div id='interval-01515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 PM</div>
                                    <div id='interval-01530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 PM</div>
                                    <div id='interval-01545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 PM</div>
                                    <div id='interval-01600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 PM</div>
                                    <div id='interval-01615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 PM</div>
                                    <div id='interval-01630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 PM</div>
                                    <div id='interval-01645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 PM</div>
                                    <div id='interval-01700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 PM</div>
                                    <div id='interval-01715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 PM</div>
                                    <div id='interval-01730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 PM</div>
                                    <div id='interval-01745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 PM</div>
                                    <div id='interval-01800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 PM</div>
                                    <div id='interval-01815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 PM</div>
                                    <div id='interval-01830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 PM</div>
                                    <div id='interval-01845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 PM</div>
                                    <div id='interval-01900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 PM</div>
                                    <div id='interval-01915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 PM</div>
                                    <div id='interval-01930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 PM</div>
                                    <div id='interval-01945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 PM</div>
                                    <div id='interval-02000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 PM</div>
                                    <div id='interval-02015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 PM</div>
                                    <div id='interval-02030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30 PM</div>
                                    <div id='interval-02045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45 PM</div>
                                    <div id='interval-02100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 PM</div>
                                    <div id='interval-02115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 PM</div>
                                    <div id='interval-02130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 PM</div>
                                    <div id='interval-02145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 PM</div>
                                    <div id='interval-02200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 PM</div>
                                    <div id='interval-02215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 PM</div>
                                    <div id='interval-02230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 PM</div>
                                    <div id='interval-02245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 PM</div>
                                    <div id='interval-02300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 PM</div>
                                    <div id='interval-02315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 PM</div>
                                    <div id='interval-02330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 PM</div>
                                    <div id='interval-02345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 PM</div>
                                </div>
                                <div className='marketplaceScheduleDay'>
                                    <div className='marketplaceScheduleDayWide'><div className='marketplaceScheduleDayWideDay'>MON</div><div className='marketplaceScheduleDayWideDate' id='dayWideDate-1'>10/28/19</div></div>
                                    <div id='interval-10000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 AM</div>
                                    <div id='interval-10015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 AM</div>
                                    <div id='interval-10030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 AM</div>
                                    <div id='interval-10045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 AM</div>
                                    <div id='interval-10100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 AM</div>
                                    <div id='interval-10115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 AM</div>
                                    <div id='interval-10130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 AM</div>
                                    <div id='interval-10145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 AM</div>
                                    <div id='interval-10200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 AM</div>
                                    <div id='interval-10215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 AM</div>
                                    <div id='interval-10230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 AM</div>
                                    <div id='interval-10245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 AM</div>
                                    <div id='interval-10300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 AM</div>
                                    <div id='interval-10315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 AM</div>
                                    <div id='interval-10330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 AM</div>
                                    <div id='interval-10345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 AM</div>
                                    <div id='interval-10400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 AM</div>
                                    <div id='interval-10415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 AM</div>
                                    <div id='interval-10430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 AM</div>
                                    <div id='interval-10445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 AM</div>
                                    <div id='interval-10500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 AM</div>
                                    <div id='interval-10515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 AM</div>
                                    <div id='interval-10530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 AM</div>
                                    <div id='interval-10545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 AM</div>
                                    <div id='interval-10600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 AM</div>
                                    <div id='interval-10615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 AM</div>
                                    <div id='interval-10630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 AM</div>
                                    <div id='interval-10645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 AM</div>
                                    <div id='interval-10700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 AM</div>
                                    <div id='interval-10715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 AM</div>
                                    <div id='interval-10730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 AM</div>
                                    <div id='interval-10745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 AM</div>
                                    <div id='interval-10800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 AM</div>
                                    <div id='interval-10815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 AM</div>
                                    <div id='interval-10830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30AM</div>
                                    <div id='interval-10845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45AM</div>
                                    <div id='interval-10900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 AM</div>
                                    <div id='interval-10915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 AM</div>
                                    <div id='interval-10930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 AM</div>
                                    <div id='interval-10945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 AM</div>
                                    <div id='interval-11000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 AM</div>
                                    <div id='interval-11015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 AM</div>
                                    <div id='interval-11030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 AM</div>
                                    <div id='interval-11045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 AM</div>
                                    <div id='interval-11100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 AM</div>
                                    <div id='interval-11115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 AM</div>
                                    <div id='interval-11130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 AM</div>
                                    <div id='interval-11145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 AM</div>
                                    <div id='interval-11200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 PM</div>
                                    <div id='interval-11215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 PM</div>
                                    <div id='interval-11230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 PM</div>
                                    <div id='interval-11245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 PM</div>
                                    <div id='interval-11300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 PM</div>
                                    <div id='interval-11315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 PM</div>
                                    <div id='interval-11330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 PM</div>
                                    <div id='interval-11345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 PM</div>
                                    <div id='interval-11400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 PM</div>
                                    <div id='interval-11415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 PM</div>
                                    <div id='interval-11430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 PM</div>
                                    <div id='interval-11445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 PM</div>
                                    <div id='interval-11500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 PM</div>
                                    <div id='interval-11515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 PM</div>
                                    <div id='interval-11530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 PM</div>
                                    <div id='interval-11545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 PM</div>
                                    <div id='interval-11600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 PM</div>
                                    <div id='interval-11615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 PM</div>
                                    <div id='interval-11630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 PM</div>
                                    <div id='interval-11645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 PM</div>
                                    <div id='interval-11700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 PM</div>
                                    <div id='interval-11715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 PM</div>
                                    <div id='interval-11730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 PM</div>
                                    <div id='interval-11745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 PM</div>
                                    <div id='interval-11800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 PM</div>
                                    <div id='interval-11815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 PM</div>
                                    <div id='interval-11830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 PM</div>
                                    <div id='interval-11845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 PM</div>
                                    <div id='interval-11900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 PM</div>
                                    <div id='interval-11915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 PM</div>
                                    <div id='interval-11930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 PM</div>
                                    <div id='interval-11945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 PM</div>
                                    <div id='interval-12000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 PM</div>
                                    <div id='interval-12015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 PM</div>
                                    <div id='interval-12030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30 PM</div>
                                    <div id='interval-12045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45 PM</div>
                                    <div id='interval-12100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 PM</div>
                                    <div id='interval-12115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 PM</div>
                                    <div id='interval-12130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 PM</div>
                                    <div id='interval-12145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 PM</div>
                                    <div id='interval-12200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 PM</div>
                                    <div id='interval-12215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 PM</div>
                                    <div id='interval-12230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 PM</div>
                                    <div id='interval-12245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 PM</div>
                                    <div id='interval-12300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 PM</div>
                                    <div id='interval-12315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 PM</div>
                                    <div id='interval-12330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 PM</div>
                                    <div id='interval-12345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 PM</div>
                                </div>
                                <div className='marketplaceScheduleDay'>
                                    <div className='marketplaceScheduleDayWide'><div className='marketplaceScheduleDayWideDay'>TUE</div><div className='marketplaceScheduleDayWideDate' id='dayWideDate-2'>10/29/19</div></div>
                                    <div id='interval-20000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 AM</div>
                                    <div id='interval-20015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 AM</div>
                                    <div id='interval-20030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 AM</div>
                                    <div id='interval-20045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 AM</div>
                                    <div id='interval-20100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 AM</div>
                                    <div id='interval-20115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 AM</div>
                                    <div id='interval-20130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 AM</div>
                                    <div id='interval-20145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 AM</div>
                                    <div id='interval-20200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 AM</div>
                                    <div id='interval-20215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 AM</div>
                                    <div id='interval-20230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 AM</div>
                                    <div id='interval-20245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 AM</div>
                                    <div id='interval-20300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 AM</div>
                                    <div id='interval-20315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 AM</div>
                                    <div id='interval-20330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 AM</div>
                                    <div id='interval-20345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 AM</div>
                                    <div id='interval-20400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 AM</div>
                                    <div id='interval-20415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 AM</div>
                                    <div id='interval-20430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 AM</div>
                                    <div id='interval-20445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 AM</div>
                                    <div id='interval-20500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 AM</div>
                                    <div id='interval-20515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 AM</div>
                                    <div id='interval-20530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 AM</div>
                                    <div id='interval-20545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 AM</div>
                                    <div id='interval-20600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 AM</div>
                                    <div id='interval-20615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 AM</div>
                                    <div id='interval-20630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 AM</div>
                                    <div id='interval-20645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 AM</div>
                                    <div id='interval-20700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 AM</div>
                                    <div id='interval-20715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 AM</div>
                                    <div id='interval-20730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 AM</div>
                                    <div id='interval-20745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 AM</div>
                                    <div id='interval-20800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 AM</div>
                                    <div id='interval-20815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 AM</div>
                                    <div id='interval-20830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30AM</div>
                                    <div id='interval-20845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45AM</div>
                                    <div id='interval-20900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 AM</div>
                                    <div id='interval-20915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 AM</div>
                                    <div id='interval-20930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 AM</div>
                                    <div id='interval-20945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 AM</div>
                                    <div id='interval-21000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 AM</div>
                                    <div id='interval-21015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 AM</div>
                                    <div id='interval-21030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 AM</div>
                                    <div id='interval-21045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 AM</div>
                                    <div id='interval-21100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 AM</div>
                                    <div id='interval-21115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 AM</div>
                                    <div id='interval-21130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 AM</div>
                                    <div id='interval-21145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 AM</div>
                                    <div id='interval-21200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 PM</div>
                                    <div id='interval-21215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 PM</div>
                                    <div id='interval-21230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 PM</div>
                                    <div id='interval-21245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 PM</div>
                                    <div id='interval-21300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 PM</div>
                                    <div id='interval-21315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 PM</div>
                                    <div id='interval-21330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 PM</div>
                                    <div id='interval-21345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 PM</div>
                                    <div id='interval-21400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 PM</div>
                                    <div id='interval-21415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 PM</div>
                                    <div id='interval-21430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 PM</div>
                                    <div id='interval-21445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 PM</div>
                                    <div id='interval-21500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 PM</div>
                                    <div id='interval-21515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 PM</div>
                                    <div id='interval-21530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 PM</div>
                                    <div id='interval-21545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 PM</div>
                                    <div id='interval-21600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 PM</div>
                                    <div id='interval-21615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 PM</div>
                                    <div id='interval-21630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 PM</div>
                                    <div id='interval-21645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 PM</div>
                                    <div id='interval-21700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 PM</div>
                                    <div id='interval-21715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 PM</div>
                                    <div id='interval-21730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 PM</div>
                                    <div id='interval-21745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 PM</div>
                                    <div id='interval-21800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 PM</div>
                                    <div id='interval-21815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 PM</div>
                                    <div id='interval-21830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 PM</div>
                                    <div id='interval-21845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 PM</div>
                                    <div id='interval-21900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 PM</div>
                                    <div id='interval-21915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 PM</div>
                                    <div id='interval-21930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 PM</div>
                                    <div id='interval-21945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 PM</div>
                                    <div id='interval-22000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 PM</div>
                                    <div id='interval-22015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 PM</div>
                                    <div id='interval-22030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30 PM</div>
                                    <div id='interval-22045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45 PM</div>
                                    <div id='interval-22100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 PM</div>
                                    <div id='interval-22115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 PM</div>
                                    <div id='interval-22130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 PM</div>
                                    <div id='interval-22145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 PM</div>
                                    <div id='interval-22200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 PM</div>
                                    <div id='interval-22215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 PM</div>
                                    <div id='interval-22230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 PM</div>
                                    <div id='interval-22245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 PM</div>
                                    <div id='interval-22300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 PM</div>
                                    <div id='interval-22315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 PM</div>
                                    <div id='interval-22330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 PM</div>
                                    <div id='interval-22345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 PM</div>
                                </div>
                                <div className='marketplaceScheduleDay'>
                                    <div className='marketplaceScheduleDayWide'><div className='marketplaceScheduleDayWideDay'>WED</div><div className='marketplaceScheduleDayWideDate' id='dayWideDate-3'>10/30/19</div></div>
                                    <div id='interval-30000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 AM</div>
                                    <div id='interval-30015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 AM</div>
                                    <div id='interval-30030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 AM</div>
                                    <div id='interval-30045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 AM</div>
                                    <div id='interval-30100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 AM</div>
                                    <div id='interval-30115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 AM</div>
                                    <div id='interval-30130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 AM</div>
                                    <div id='interval-30145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 AM</div>
                                    <div id='interval-30200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 AM</div>
                                    <div id='interval-30215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 AM</div>
                                    <div id='interval-30230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 AM</div>
                                    <div id='interval-30245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 AM</div>
                                    <div id='interval-30300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 AM</div>
                                    <div id='interval-30315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 AM</div>
                                    <div id='interval-30330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 AM</div>
                                    <div id='interval-30345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 AM</div>
                                    <div id='interval-30400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 AM</div>
                                    <div id='interval-30415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 AM</div>
                                    <div id='interval-30430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 AM</div>
                                    <div id='interval-30445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 AM</div>
                                    <div id='interval-30500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 AM</div>
                                    <div id='interval-30515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 AM</div>
                                    <div id='interval-30530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 AM</div>
                                    <div id='interval-30545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 AM</div>
                                    <div id='interval-30600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 AM</div>
                                    <div id='interval-30615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 AM</div>
                                    <div id='interval-30630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 AM</div>
                                    <div id='interval-30645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 AM</div>
                                    <div id='interval-30700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 AM</div>
                                    <div id='interval-30715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 AM</div>
                                    <div id='interval-30730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 AM</div>
                                    <div id='interval-30745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 AM</div>
                                    <div id='interval-30800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 AM</div>
                                    <div id='interval-30815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 AM</div>
                                    <div id='interval-30830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30AM</div>
                                    <div id='interval-30845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45AM</div>
                                    <div id='interval-30900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 AM</div>
                                    <div id='interval-30915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 AM</div>
                                    <div id='interval-30930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 AM</div>
                                    <div id='interval-30945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 AM</div>
                                    <div id='interval-31000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 AM</div>
                                    <div id='interval-31015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 AM</div>
                                    <div id='interval-31030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 AM</div>
                                    <div id='interval-31045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 AM</div>
                                    <div id='interval-31100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 AM</div>
                                    <div id='interval-31115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 AM</div>
                                    <div id='interval-31130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 AM</div>
                                    <div id='interval-31145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 AM</div>
                                    <div id='interval-31200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 PM</div>
                                    <div id='interval-31215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 PM</div>
                                    <div id='interval-31230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 PM</div>
                                    <div id='interval-31245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 PM</div>
                                    <div id='interval-31300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 PM</div>
                                    <div id='interval-31315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 PM</div>
                                    <div id='interval-31330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 PM</div>
                                    <div id='interval-31345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 PM</div>
                                    <div id='interval-31400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 PM</div>
                                    <div id='interval-31415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 PM</div>
                                    <div id='interval-31430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 PM</div>
                                    <div id='interval-31445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 PM</div>
                                    <div id='interval-31500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 PM</div>
                                    <div id='interval-31515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 PM</div>
                                    <div id='interval-31530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 PM</div>
                                    <div id='interval-31545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 PM</div>
                                    <div id='interval-31600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 PM</div>
                                    <div id='interval-31615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 PM</div>
                                    <div id='interval-31630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 PM</div>
                                    <div id='interval-31645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 PM</div>
                                    <div id='interval-31700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 PM</div>
                                    <div id='interval-31715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 PM</div>
                                    <div id='interval-31730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 PM</div>
                                    <div id='interval-31745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 PM</div>
                                    <div id='interval-31800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 PM</div>
                                    <div id='interval-31815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 PM</div>
                                    <div id='interval-31830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 PM</div>
                                    <div id='interval-31845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 PM</div>
                                    <div id='interval-31900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 PM</div>
                                    <div id='interval-31915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 PM</div>
                                    <div id='interval-31930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 PM</div>
                                    <div id='interval-31945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 PM</div>
                                    <div id='interval-32000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 PM</div>
                                    <div id='interval-32015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 PM</div>
                                    <div id='interval-32030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30 PM</div>
                                    <div id='interval-32045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45 PM</div>
                                    <div id='interval-32100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 PM</div>
                                    <div id='interval-32115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 PM</div>
                                    <div id='interval-32130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 PM</div>
                                    <div id='interval-32145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 PM</div>
                                    <div id='interval-32200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 PM</div>
                                    <div id='interval-32215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 PM</div>
                                    <div id='interval-32230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 PM</div>
                                    <div id='interval-32245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 PM</div>
                                    <div id='interval-32300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 PM</div>
                                    <div id='interval-32315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 PM</div>
                                    <div id='interval-32330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 PM</div>
                                    <div id='interval-32345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 PM</div>
                                </div>
                                <div className='marketplaceScheduleDay'>
                                    <div className='marketplaceScheduleDayWide'><div className='marketplaceScheduleDayWideDay'>THU</div><div className='marketplaceScheduleDayWideDate' id='dayWideDate-4'>10/31/19</div></div>
                                    <div id='interval-40000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 AM</div>
                                    <div id='interval-40015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 AM</div>
                                    <div id='interval-40030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 AM</div>
                                    <div id='interval-40045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 AM</div>
                                    <div id='interval-40100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 AM</div>
                                    <div id='interval-40115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 AM</div>
                                    <div id='interval-40130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 AM</div>
                                    <div id='interval-40145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 AM</div>
                                    <div id='interval-40200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 AM</div>
                                    <div id='interval-40215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 AM</div>
                                    <div id='interval-40230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 AM</div>
                                    <div id='interval-40245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 AM</div>
                                    <div id='interval-40300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 AM</div>
                                    <div id='interval-40315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 AM</div>
                                    <div id='interval-40330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 AM</div>
                                    <div id='interval-40345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 AM</div>
                                    <div id='interval-40400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 AM</div>
                                    <div id='interval-40415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 AM</div>
                                    <div id='interval-40430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 AM</div>
                                    <div id='interval-40445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 AM</div>
                                    <div id='interval-40500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 AM</div>
                                    <div id='interval-40515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 AM</div>
                                    <div id='interval-40530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 AM</div>
                                    <div id='interval-40545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 AM</div>
                                    <div id='interval-40600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 AM</div>
                                    <div id='interval-40615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 AM</div>
                                    <div id='interval-40630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 AM</div>
                                    <div id='interval-40645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 AM</div>
                                    <div id='interval-40700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 AM</div>
                                    <div id='interval-40715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 AM</div>
                                    <div id='interval-40730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 AM</div>
                                    <div id='interval-40745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 AM</div>
                                    <div id='interval-40800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 AM</div>
                                    <div id='interval-40815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 AM</div>
                                    <div id='interval-40830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30AM</div>
                                    <div id='interval-40845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45AM</div>
                                    <div id='interval-40900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 AM</div>
                                    <div id='interval-40915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 AM</div>
                                    <div id='interval-40930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 AM</div>
                                    <div id='interval-40945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 AM</div>
                                    <div id='interval-41000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 AM</div>
                                    <div id='interval-41015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 AM</div>
                                    <div id='interval-41030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 AM</div>
                                    <div id='interval-41045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 AM</div>
                                    <div id='interval-41100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 AM</div>
                                    <div id='interval-41115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 AM</div>
                                    <div id='interval-41130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 AM</div>
                                    <div id='interval-41145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 AM</div>
                                    <div id='interval-41200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 PM</div>
                                    <div id='interval-41215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 PM</div>
                                    <div id='interval-41230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 PM</div>
                                    <div id='interval-41245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 PM</div>
                                    <div id='interval-41300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 PM</div>
                                    <div id='interval-41315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 PM</div>
                                    <div id='interval-41330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 PM</div>
                                    <div id='interval-41345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 PM</div>
                                    <div id='interval-41400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 PM</div>
                                    <div id='interval-41415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 PM</div>
                                    <div id='interval-41430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 PM</div>
                                    <div id='interval-41445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 PM</div>
                                    <div id='interval-41500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 PM</div>
                                    <div id='interval-41515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 PM</div>
                                    <div id='interval-41530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 PM</div>
                                    <div id='interval-41545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 PM</div>
                                    <div id='interval-41600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 PM</div>
                                    <div id='interval-41615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 PM</div>
                                    <div id='interval-41630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 PM</div>
                                    <div id='interval-41645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 PM</div>
                                    <div id='interval-41700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 PM</div>
                                    <div id='interval-41715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 PM</div>
                                    <div id='interval-41730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 PM</div>
                                    <div id='interval-41745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 PM</div>
                                    <div id='interval-41800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 PM</div>
                                    <div id='interval-41815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 PM</div>
                                    <div id='interval-41830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 PM</div>
                                    <div id='interval-41845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 PM</div>
                                    <div id='interval-41900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 PM</div>
                                    <div id='interval-41915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 PM</div>
                                    <div id='interval-41930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 PM</div>
                                    <div id='interval-41945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 PM</div>
                                    <div id='interval-42000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 PM</div>
                                    <div id='interval-42015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 PM</div>
                                    <div id='interval-42030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30 PM</div>
                                    <div id='interval-42045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45 PM</div>
                                    <div id='interval-42100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 PM</div>
                                    <div id='interval-42115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 PM</div>
                                    <div id='interval-42130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 PM</div>
                                    <div id='interval-42145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 PM</div>
                                    <div id='interval-42200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 PM</div>
                                    <div id='interval-42215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 PM</div>
                                    <div id='interval-42230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 PM</div>
                                    <div id='interval-42245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 PM</div>
                                    <div id='interval-42300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 PM</div>
                                    <div id='interval-42315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 PM</div>
                                    <div id='interval-42330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 PM</div>
                                    <div id='interval-42345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 PM</div>
                                </div>
                                <div className='marketplaceScheduleDay'>
                                    <div className='marketplaceScheduleDayWide'><div className='marketplaceScheduleDayWideDay'>FRI</div><div className='marketplaceScheduleDayWideDate' id='dayWideDate-5'>11/01/19</div></div>
                                    <div id='interval-50000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 AM</div>
                                    <div id='interval-50015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 AM</div>
                                    <div id='interval-50030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 AM</div>
                                    <div id='interval-50045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 AM</div>
                                    <div id='interval-50100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 AM</div>
                                    <div id='interval-50115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 AM</div>
                                    <div id='interval-50130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 AM</div>
                                    <div id='interval-50145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 AM</div>
                                    <div id='interval-50200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 AM</div>
                                    <div id='interval-50215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 AM</div>
                                    <div id='interval-50230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 AM</div>
                                    <div id='interval-50245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 AM</div>
                                    <div id='interval-50300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 AM</div>
                                    <div id='interval-50315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 AM</div>
                                    <div id='interval-50330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 AM</div>
                                    <div id='interval-50345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 AM</div>
                                    <div id='interval-50400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 AM</div>
                                    <div id='interval-50415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 AM</div>
                                    <div id='interval-50430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 AM</div>
                                    <div id='interval-50445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 AM</div>
                                    <div id='interval-50500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 AM</div>
                                    <div id='interval-50515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 AM</div>
                                    <div id='interval-50530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 AM</div>
                                    <div id='interval-50545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 AM</div>
                                    <div id='interval-50600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 AM</div>
                                    <div id='interval-50615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 AM</div>
                                    <div id='interval-50630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 AM</div>
                                    <div id='interval-50645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 AM</div>
                                    <div id='interval-50700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 AM</div>
                                    <div id='interval-50715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 AM</div>
                                    <div id='interval-50730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 AM</div>
                                    <div id='interval-50745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 AM</div>
                                    <div id='interval-50800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 AM</div>
                                    <div id='interval-50815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 AM</div>
                                    <div id='interval-50830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30AM</div>
                                    <div id='interval-50845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45AM</div>
                                    <div id='interval-50900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 AM</div>
                                    <div id='interval-50915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 AM</div>
                                    <div id='interval-50930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 AM</div>
                                    <div id='interval-50945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 AM</div>
                                    <div id='interval-51000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 AM</div>
                                    <div id='interval-51015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 AM</div>
                                    <div id='interval-51030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 AM</div>
                                    <div id='interval-51045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 AM</div>
                                    <div id='interval-51100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 AM</div>
                                    <div id='interval-51115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 AM</div>
                                    <div id='interval-51130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 AM</div>
                                    <div id='interval-51145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 AM</div>
                                    <div id='interval-51200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 PM</div>
                                    <div id='interval-51215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 PM</div>
                                    <div id='interval-51230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 PM</div>
                                    <div id='interval-51245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 PM</div>
                                    <div id='interval-51300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 PM</div>
                                    <div id='interval-51315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 PM</div>
                                    <div id='interval-51330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 PM</div>
                                    <div id='interval-51345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 PM</div>
                                    <div id='interval-51400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 PM</div>
                                    <div id='interval-51415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 PM</div>
                                    <div id='interval-51430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 PM</div>
                                    <div id='interval-51445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 PM</div>
                                    <div id='interval-51500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 PM</div>
                                    <div id='interval-51515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 PM</div>
                                    <div id='interval-51530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 PM</div>
                                    <div id='interval-51545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 PM</div>
                                    <div id='interval-51600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 PM</div>
                                    <div id='interval-51615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 PM</div>
                                    <div id='interval-51630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 PM</div>
                                    <div id='interval-51645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 PM</div>
                                    <div id='interval-51700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 PM</div>
                                    <div id='interval-51715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 PM</div>
                                    <div id='interval-51730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 PM</div>
                                    <div id='interval-51745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 PM</div>
                                    <div id='interval-51800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 PM</div>
                                    <div id='interval-51815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 PM</div>
                                    <div id='interval-51830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 PM</div>
                                    <div id='interval-51845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 PM</div>
                                    <div id='interval-51900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 PM</div>
                                    <div id='interval-51915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 PM</div>
                                    <div id='interval-51930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 PM</div>
                                    <div id='interval-51945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 PM</div>
                                    <div id='interval-52000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 PM</div>
                                    <div id='interval-52015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 PM</div>
                                    <div id='interval-52030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30 PM</div>
                                    <div id='interval-52045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45 PM</div>
                                    <div id='interval-52100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 PM</div>
                                    <div id='interval-52115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 PM</div>
                                    <div id='interval-52130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 PM</div>
                                    <div id='interval-52145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 PM</div>
                                    <div id='interval-52200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 PM</div>
                                    <div id='interval-52215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 PM</div>
                                    <div id='interval-52230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 PM</div>
                                    <div id='interval-52245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 PM</div>
                                    <div id='interval-52300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 PM</div>
                                    <div id='interval-52315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 PM</div>
                                    <div id='interval-52330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 PM</div>
                                    <div id='interval-52345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 PM</div>
                                </div>
                                <div className='marketplaceScheduleDay'>
                                    <div className='marketplaceScheduleDayWide'><div className='marketplaceScheduleDayWideDay'>SAT</div><div className='marketplaceScheduleDayWideDate' id='dayWideDate-6'>11/02/19</div></div>
                                    <div id='interval-60000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 AM</div>
                                    <div id='interval-60015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 AM</div>
                                    <div id='interval-60030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 AM</div>
                                    <div id='interval-60045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 AM</div>
                                    <div id='interval-60100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 AM</div>
                                    <div id='interval-60115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 AM</div>
                                    <div id='interval-60130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 AM</div>
                                    <div id='interval-60145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 AM</div>
                                    <div id='interval-60200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 AM</div>
                                    <div id='interval-60215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 AM</div>
                                    <div id='interval-60230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 AM</div>
                                    <div id='interval-60245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 AM</div>
                                    <div id='interval-60300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 AM</div>
                                    <div id='interval-60315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 AM</div>
                                    <div id='interval-60330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 AM</div>
                                    <div id='interval-60345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 AM</div>
                                    <div id='interval-60400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 AM</div>
                                    <div id='interval-60415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 AM</div>
                                    <div id='interval-60430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 AM</div>
                                    <div id='interval-60445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 AM</div>
                                    <div id='interval-60500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 AM</div>
                                    <div id='interval-60515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 AM</div>
                                    <div id='interval-60530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 AM</div>
                                    <div id='interval-60545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 AM</div>
                                    <div id='interval-60600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 AM</div>
                                    <div id='interval-60615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 AM</div>
                                    <div id='interval-60630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 AM</div>
                                    <div id='interval-60645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 AM</div>
                                    <div id='interval-60700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 AM</div>
                                    <div id='interval-60715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 AM</div>
                                    <div id='interval-60730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 AM</div>
                                    <div id='interval-60745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 AM</div>
                                    <div id='interval-60800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 AM</div>
                                    <div id='interval-60815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 AM</div>
                                    <div id='interval-60830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30AM</div>
                                    <div id='interval-60845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45AM</div>
                                    <div id='interval-60900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 AM</div>
                                    <div id='interval-60915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 AM</div>
                                    <div id='interval-60930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 AM</div>
                                    <div id='interval-60945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 AM</div>
                                    <div id='interval-61000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 AM</div>
                                    <div id='interval-61015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 AM</div>
                                    <div id='interval-61030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 AM</div>
                                    <div id='interval-61045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 AM</div>
                                    <div id='interval-61100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 AM</div>
                                    <div id='interval-61115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 AM</div>
                                    <div id='interval-61130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 AM</div>
                                    <div id='interval-61145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 AM</div>
                                    <div id='interval-61200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:00 PM</div>
                                    <div id='interval-61215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:15 PM</div>
                                    <div id='interval-61230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:30 PM</div>
                                    <div id='interval-61245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>12:45 PM</div>
                                    <div id='interval-61300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:00 PM</div>
                                    <div id='interval-61315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:15 PM</div>
                                    <div id='interval-61330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:30 PM</div>
                                    <div id='interval-61345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>1:45 PM</div>
                                    <div id='interval-61400' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:00 PM</div>
                                    <div id='interval-61415' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:15 PM</div>
                                    <div id='interval-61430' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:30 PM</div>
                                    <div id='interval-61445' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>2:45 PM</div>
                                    <div id='interval-61500' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:00 PM</div>
                                    <div id='interval-61515' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:15 PM</div>
                                    <div id='interval-61530' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:30 PM</div>
                                    <div id='interval-61545' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>3:45 PM</div>
                                    <div id='interval-61600' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:00 PM</div>
                                    <div id='interval-61615' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:15 PM</div>
                                    <div id='interval-61630' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:30 PM</div>
                                    <div id='interval-61645' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>4:45 PM</div>
                                    <div id='interval-61700' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:00 PM</div>
                                    <div id='interval-61715' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:15 PM</div>
                                    <div id='interval-61730' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:30 PM</div>
                                    <div id='interval-61745' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>5:45 PM</div>
                                    <div id='interval-61800' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:00 PM</div>
                                    <div id='interval-61815' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:15 PM</div>
                                    <div id='interval-61830' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:30 PM</div>
                                    <div id='interval-61845' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>6:45 PM</div>
                                    <div id='interval-61900' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:00 PM</div>
                                    <div id='interval-61915' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:15 PM</div>
                                    <div id='interval-61930' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:30 PM</div>
                                    <div id='interval-61945' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>7:45 PM</div>
                                    <div id='interval-62000' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:00 PM</div>
                                    <div id='interval-62015' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:15 PM</div>
                                    <div id='interval-62030' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:30 PM</div>
                                    <div id='interval-62045' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>8:45 PM</div>
                                    <div id='interval-62100' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:00 PM</div>
                                    <div id='interval-62115' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:15 PM</div>
                                    <div id='interval-62130' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:30 PM</div>
                                    <div id='interval-62145' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>9:45 PM</div>
                                    <div id='interval-62200' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:00 PM</div>
                                    <div id='interval-62215' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:15 PM</div>
                                    <div id='interval-62230' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:30 PM</div>
                                    <div id='interval-62245' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>10:45 PM</div>
                                    <div id='interval-62300' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:00 PM</div>
                                    <div id='interval-62315' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:15 PM</div>
                                    <div id='interval-62330' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:30 PM</div>
                                    <div id='interval-62345' onClick={onIntervalClick} className='marketplaceIntervalUnavailable'>11:45 PM</div>
                                </div>
                            </div>
                            <div className='gap-2 mt-3 pb-3'>
                                <button type='submit' className='marketplaceButton' onClick={(e) => { onCancelClick(e) }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <MarketplaceSpinner hidden={loading} />
        </>
    );
};

export default ScheduleAvailability;
