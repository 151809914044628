
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { MediaTime } from '../../../redux/process/processreducer';

const FormVideo = (props) => {

    const { step } = props;
    const videoRef = useRef(null);
    const mediaTime = useSelector((state) => state.process.mediaTime);
    const dispatch = useDispatch();

    let mediaType = step.stepDetails.MediaType;
    let mediaSource = step.stepDetails.MediaSource;
    let mediaHeight = step.stepDetails.MediaHeight;
    let mediaAutoplay = step.stepDetails.MediaAutoplay === 'Yes' ? true : false;

    useEffect(() => {
        if (mediaTime > 0)
            videoRef.current.currentTime = mediaTime;
        const interval = setInterval(async () => {
            const elapsed_sec = videoRef.current.currentTime;
            dispatch(MediaTime(elapsed_sec));
        }, 100);

        return () => {
            dispatch(MediaTime(0));
            clearInterval(interval);
        };
    }, [])


    if (mediaType.toLowerCase() === 'url') {

        if (mediaSource.includes('mp4')) {

            return (
                <video id='marketplaceProcessVideo' className='marketplaceProcessVideo mt-2' playsInline height={mediaHeight} style={{ width: '100%' }} ref={videoRef} controls autoPlay={mediaAutoplay} controlsList='nofullscreen nodownload'  >
                    <source src={mediaSource} />
                </video>
            );
        }
        else {
            return (
                <iframe src={mediaSource} height={mediaHeight} playsInline width='100%' ref={videoRef} autoPlay={mediaAutoplay} frameBorder='1' ></iframe>
            );
        }

    }
    else if (mediaType.toLowerCase() === 'file') {
        <video id='marketplaceProcessVideo' className='marketplaceProcessVideo mt-2' playsInline height={mediaHeight} style={{ width: '100%' }} ref={videoRef} controls autoPlay={mediaAutoplay} controlsList='nofullscreen nodownload'  >
            <source type='video/mp4' src={'data:video/mp4;base64,' + mediaSource} />
        </video>
    }


};

export default FormVideo;

FormVideo.propTypes = {
    step: PropTypes.any.isRequired,
};
