import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    assessmentCompletedConversionURL: '',
    AssessmentCompletedConversionJS: '',
    backgroundURL:''
};

export const FlowSRCReducer = createSlice({
    name: 'FlowSRC',
    initialState,
    reducers: {
        SetAssessmentCompletedConversionURL: (state, action) => {
            state.assessmentCompletedConversionURL = action.payload.assessmentCompletedConversionURL;
        }, 
        SetAssessmentCompletedConversionJS: (state, action) => {
            state.AssessmentCompletedConversionJS = action.payload.AssessmentCompletedConversionJS;
        },
        SetBackgroundURL: (state, action) => {
            state.backgroundURL = action.payload.backgroundURL;
        },
    }
});

export const { SetAssessmentCompletedConversionURL, SetAssessmentCompletedConversionJS, SetBackgroundURL } = FlowSRCReducer.actions;

export default FlowSRCReducer.reducer;