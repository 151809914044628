import { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { sanitize } from 'dompurify';
import $ from 'jquery';
import axios from 'axios';

import { ShowMarketplaceError } from '../../utils/core/coreutil';
import GatewayAPI from '../../api/gateway/gatewayapi';
import MarketplaceSpinner from '../../components/shared/marketplacespinner/marketplacespinner';
import { SetBackgroundURL } from '../../redux/flowsrc/flowsrcreducer';

const Gateway = () => {

    const location = useLocation()
    const marketplaceSessionGUID = useSelector((state) => state.core.marketplaceSessionGUID);
    const agentProfileGUID = useSelector((state) => state.core.agentProfileGUID);
    const agentProfileID = useSelector((state) => state.core.agentProfileID);
    const backgroundURL = useSelector((state) => state.flowsrc.backgroundURL);
    const [data, setData] = useState({
        marketplaceSessionGUID: marketplaceSessionGUID,
        agentProfileGUID: agentProfileGUID,
        agentProfileID: agentProfileID,
        assessmentCompletedConversionJS: '',
        assessmentCompletedConversionURL: '',
        backgroundURL: '',
        existingCallDateTime: '',
        existingSourcingScheduleID: 0
    });
    const [hideConversionURL, setHideConversionURL] = useState(true);
    const [hideConversionJS, setHideConversionJS] = useState(true);
    const [formLabel, setFormLabel] = useState(null);
    const [formLabelType, setFormLabelType] = useState(location?.state?.formLabelType ? location?.state?.formLabelType : 'Default');
    const [showButton, setShowButton] = useState({
        rescheduleCall: false,
        scheduleCall: false,
        cancelCall: false,
        editProfile: false,
        signUp: false,
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [iframeData, setIframeData] = useState('');

    useEffect(() => {
        gatewayOnLoad();
    }, []);

    const gatewayOnLoad = () => {

        GatewayAPI.GatewayCheck(data).then((response) => {

            if (response.assessmentCompletedConversionURL !== '') {
                setData(values => {
                    return {
                        ...values,
                        assessmentCompletedConversionURL: response.assessmentCompletedConversionURL,
                        assessmentCompletedConversionJS: response.assessmentCompletedConversionJS,
                    }
                });

                setHideConversionURL(false);
            }

            if (response.assessmentCompletedConversionJS === 'recruitics') {

                $.getScript('https://jsv3.recruitics.com/3c7b1874-14f0-11ed-baf6-05069cd7a220.js', function () {
                    window.rx.trigger('apply');
                });
            }
            else if (response.assessmentCompletedConversionJS)
                setHideConversionJS(false);


            if (response.backgroundURL)
                setData(values => {
                    return {
                        ...values,
                        backgroundURL: response.backgroundURL
                    }
                });


            let gatewayStatus = response;

            switch (gatewayStatus.responseStatus.toLowerCase()) {
                case 'banned':
                    setFormLabel(<p>There are currently no matching Marketplace opportunities.</p>);
                    setShowButton(values => {
                        return {
                            ...values,
                            editProfile: true
                        }
                    });
                    break;
                case 'blocked':
                    setFormLabel(<p>There are currently no matching Marketplace opportunities.</p>);
                    setShowButton(values => {
                        return {
                            ...values,
                            editProfile: true
                        }
                    });
                    break;
                case 'existingcall':
                    setFormLabel(<p>You currently have a call scheduled for <strong>{gatewayStatus.existingCallDateTime}</strong>.  Would you like to reschedule or cancel this call?</p>);
                    setData(values => {
                        return {
                            ...values,
                            existingCallDateTime: gatewayStatus.existingCallDateTime,
                            existingSourcingScheduleID: gatewayStatus.sourcingScheduleID,
                        }
                    });
                    setShowButton(values => {
                        return {
                            ...values,
                            rescheduleCall: true,
                            cancelCall: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'bypass-match':
                    setFormLabel(<p>Congratulations you are a good match for this opportunity and can bypass the required Marketplace Coordinator call.  Click Sign Up to select a certification class which best fits your schedule.</p>);
                    setShowButton(values => {
                        return {
                            ...values,
                            signUp: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'bypass-confirmed':
                    setFormLabel(
                        <>
                            {
                                response.backgroundURL ?
                                    <>
                                        <p>
                                            You're almost done!
                                        </p>
                                        <p>
                                            Next, you'll need to complete a background check, which you can do by <strong>{<a href={response.backgroundURL} target='_blank'>Clicking Here</a>}</strong>.
                                        </p>
                                        <p>
                                            Clicking that link will take you to our third-party background check vendor, KarmaCheck.
                                        </p>
                                        <p>
                                            After you complete the background check, you can expect a couple additional steps:
                                        </p>
                                        <p>
                                            <ul>
                                                <li>As soon as we receive your background check results, you will receive an email with some forms you'll need to fill out and return, including an Independent Contractor agreement and W-9.</li>
                                                <li>After we've received those forms, and prior to the start of certification, you will receive a welcome letter containing additional information to prepare you for class.</li>
                                            </ul>
                                        </p>
                                        <p>
                                            <strong>We encourage you to complete these final steps as soon as possible to lock in your spot in the upcoming certification class, which will be filled on a first come, first served basis.</strong>
                                        </p>
                                        <p>
                                            We will also send you an email with these next steps, including the link to complete your background check. If you decide not to proceed using the link above, please be on the lookout for that email. If you don’t receive the email within the next hour or so, please check your spam/junk folder.
                                            <br />
                                            <br />
                                            Thank you for your continued interest in joining the NexRep Marketplace!
                                            <br />
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p>
                                            You're almost done!
                                        </p>
                                        <p>
                                            <strong>As a next step, you will receive an email with some forms you'll need to fill out and return, including an Independent Contractor agreement and W-9.</strong>
                                        </p>
                                        <p>
                                            You should receive that email within the next 24 hours, so be on the lookout for it. If you don't receive the email in your inbox, <strong>please check your junk/spam folder to see if it ended up there.</strong>
                                        </p>
                                        <p>
                                            We encourage you to complete this final step as soon as possible to lock in your spot in the upcoming certification class, which will be filled on a first come, first served basis.
                                        </p>
                                        <p>
                                            After we've received your completed forms, and prior to the start of certification, <strong>you will receive a welcome letter email </strong> containing additional information to prepare you for class.
                                        </p>
                                        <p>
                                            Thank you for your continued interest in joining the NexRep Marketplace!
                                        </p>
                                    </>
                            }
                        </>
                    );
                    setFormLabelType('Expanded');
                    setShowButton(values => {
                        return {
                            ...values,
                            editProfile: true
                        }
                    });
                    break;
                case 'programlanguagesource-match':
                    setFormLabel(<p>Congratulations, you are a match for the opportunity that you are responding to.  To learn more about this opportunity click Schedule Call and speak with a Marketplace Coordinator.</p>);
                    setShowButton(values => {
                        return {
                            ...values,
                            scheduleCall: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'programonlysource-match':
                    setFormLabel(<p>Congratulations, you are a match for the opportunity that you are responding to. To learn more about this opportunity click Schedule Call and speak with a Marketplace Coordinator.</p>);
                    setShowButton(values => {
                        return {
                            ...values,
                            scheduleCall: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'nonsource-match':
                    setFormLabel(<p>While you don't match the opportunity that you are responding to, there are other matching opportunities for you in the Marketplace.  To learn more about these opportunities click Schedule Call and speak with a Marketplace Coordinator.</p>);
                    setShowButton(values => {
                        return {
                            ...values,
                            scheduleCall: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'match':
                    setFormLabel(<p>Congratulations, you match opportunities in the Marketplace.  To learn more about these opportunities click Schedule Call and speak with a Marketplace Coordinator.</p>);
                    setShowButton(values => {
                        return {
                            ...values,
                            scheduleCall: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'nomatch':
                    setFormLabel(<p>While there is not a good match for you right now, please check back because new opportunities are added to the Marketplace all the time.</p>);
                    setShowButton(values => {
                        return {
                            ...values,
                            editProfile: true
                        }
                    });
                    break;
                default:
                    setFormLabel(<p>While there is not a good match for you right now, please check back because new opportunities are added to the Marketplace all the time.</p>);
                    setShowButton(values => {
                        return {
                            ...values,
                            editProfile: true
                        }
                    });
                    break;
            }
            setLoading(false);
        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0);
            }
            else
                ShowMarketplaceError(e.message, 'gateway.js', 'gatewayOnLoad()');
        });
    };

    const editProfileClick = () => {
        navigate('/profileedit');
    };

    const scheduleCallClick = () => {
        navigate('/scheduleavailability');
    };

    const rescheduleCallClick = () => {
        navigate('/scheduleavailability', { state: { existingCallDateTime: data.existingCallDateTime, existingSourcingScheduleID: data.existingSourcingScheduleID } });
    };

    const signUpClick = () => {
        navigate('/classavailibility', { state: { existingCallDateTime: data.existingCallDateTime, existingSourcingScheduleID: data.existingSourcingScheduleID } });
    };

    const cancelCallClick = () => {
        navigate('/schedulecancel', { state: { existingCallDateTime: data.existingCallDateTime, existingSourcingScheduleID: data.existingSourcingScheduleID } });
    };

    return (
        <>{!loading ?
            <div className='marketplaceFormContainer'>
                <form id='marketplaceForm' className='marketplaceForm' style={formLabelType === 'Expanded' ? (data.backgroundURL ? { height: '700px' } : { height: '550px' }) : { height: '240px' }}>
                    <div className='marketplaceFormContent'>
                        <h3 className='marketplaceFormTitle'>NexRep Contractor Marketplace</h3>
                        {formLabel}
                        <img hidden={hideConversionURL} src={data.assessmentCompletedConversionURL} />
                        <div className='gap-2 mt-3'>
                            <button hidden={!showButton.rescheduleCall} onClick={rescheduleCallClick} type='submit' className='marketplaceButton mt-2' >
                                Rechedule Call
                            </button>
                            <button hidden={!showButton.cancelCall} onClick={cancelCallClick} type='submit' className='marketplaceButton mt-2' >
                                Cancel Call
                            </button>
                            <button hidden={!showButton.scheduleCall} onClick={scheduleCallClick} type='submit' className='marketplaceButton mt-2' >
                                Schedule Call
                            </button>
                            <button hidden={!showButton.signUp} onClick={signUpClick} type='submit' className='marketplaceButton mt-2' >
                                Sign Up
                            </button>
                            <button hidden={!showButton.editProfile} onClick={editProfileClick} type='submit' className='marketplaceButton mt-2' >
                                Edit Profile
                            </button>
                        </div>
                    </div>
                </form>
                {
                    !hideConversionJS ?
                        <div>{data.assessmentCompletedConversionJS}</div>
                        : null
                }
            </div>
            : <MarketplaceSpinner hidden={loading} />
        }
        </>
    );

};

export default Gateway;