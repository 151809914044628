import axios from "axios";

import { SOCRATESAPIURL } from "../../config/constant";


const ScheduleAPI = ({

    ScheduleAvailability: function (data) {

        try {

            return axios({
                url: `${SOCRATESAPIURL}/SocratesMarketplace/ScheduleAvailability`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID,
                    'startingDate': data.startDate,
                    'timeZone': data.timeZone
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    ScheduleConfirm: function (data, languageFluency) {

        try {

            return axios({
                url: `${SOCRATESAPIURL}/SocratesMarketplace/ScheduleConfirm`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID,
                    
                    'existingSourcingScheduleID': data.existingSourcingScheduleID,
                    'scheduledDate': data.scheduledDate,
                    'scheduledTime': data.scheduledTime,
                    'timeZone': data.timeZone,

                    'firstName': data.firstName,
                    'lastName': data.lastName,
                    'address1': data.address1,
                    'address2': data.address2,
                    'city': data.city,
                    'state': data.state,
                    'zip': data.zip,
                    'homePhone': data.homePhone,
                    'cellPhone': data.cellPhone,
                    'smsOptIn': data.smsOptIn,
                    'email': data.email,
                    'education': data.education,
                    'languageFluency': languageFluency,
                    'contactCenterPhoneSupport': data.contactCenterPhoneSupport === true ? 'Yes' : 'No',
                    'contactCenterChatSupport': data.contactCenterChatSupport ? 'Yes' : 'No',
                    'contactCenterEmailSupport': data.contactCenterEmailSupport ? 'Yes' : 'No'
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    ScheduleCancel: function (data) {

        try {

            return axios({
                url: `${SOCRATESAPIURL}/SocratesMarketplace/ScheduleCancel`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID,                   
                    'existingSourcingScheduleID': data.existingSourcingScheduleID,
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
});

export default ScheduleAPI;