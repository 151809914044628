import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { hotjar } from 'react-hotjar';
import $ from 'jquery';

import { AgentProfile, login } from '../../redux/core/corereducer';
import { ShowMarketplaceError } from '../../utils/core/coreutil';
import FlowSRCAPI from '../../api/flowsrc/flowsrcapi';

const ProfileExisting = (props) => {
    const { data, setData } = props;
    const promptSources = useSelector((state) => state.core.promptSources);
    const [showOther, setShowOther] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [lock, setLock] = useState(false);

    useEffect(() => {

        if (hotjar.initialized()) {
            hotjar.stateChange('/profileexisting');
        }

        if (!promptSources)
            $('.marketplaceForm').css('height', '420px');

    }, []);

    const loginClick = (event) => {
        event.preventDefault();

        if (data.email === '' || data.password === '') {
            toast.error('All fields are required.');
            return;
        }

        if (data.webTrafficSourceDetailID === 0 && promptSources) {
            toast.error('All fields are required.');
            return;
        }

        else if (data.webTrafficSourceDetailID === 100554 && data.webTrafficSourceOther === '') {
            toast.error('All fields are required.');
            return;
        }

        setLock(true);
        FlowSRCAPI.ProfileExisting(data).then((response) => {

            let existingProfileStatus = response.responseStatus;

            switch (existingProfileStatus.toLowerCase()) {
                case 'success':
                    dispatch(login());
                    dispatch(AgentProfile({ agentProfileGUID: response.agentProfileGUID, agentProfileID: response.agentProfileID }))
                    navigate('/residancyupdate', { props: props })
                    break;
                case 'email not found':
                    toast.error('Email Not Found.');
                    break;
                case 'bad password':
                    toast.error('Username or Password Incorrect.');
                    break;
                default:
                    toast.error(existingProfileStatus);
            }

            setLock(false);

        }).catch((e) => {
            ShowMarketplaceError(e.message, 'profileexisting.js', 'loginClick()');
            setLock(false);
        });

    };

    const handleInputChange = (e) => {
        e.preventDefault();

        if (e.target.name === 'webTrafficSourceDetailID' && e.target.value === 100554) {
            setShowOther(true);
            $('.marketplaceForm').css('height', '560px');
        } else if (e.target.name === 'webTrafficSourceDetailID' && e.target.value !== 100554) {
            setShowOther(false);
            $('.marketplaceForm').css('height', '500px');
        }

        setData(prevState => {
            return { ...prevState, [e.target.name]: e.target.value }
        })
    };

    const isValidEmail = (email) => {

        let emailParts = email.split('@');

        if (emailParts.length !== 2) {
            return false;
        }

        let emailName = emailParts[0];
        let emailDomain = emailParts[1];

        if (emailName.length < 1 || emailDomain.length < 1) {
            return false;
        }

        let domainParts = emailDomain.split('.');

        if (domainParts.length < 2) {
            return false;
        }

        for (let i = 0; i < domainParts.length; i++) {
            if (domainParts[i].length === 0)
                return false;
        }

        return true;
    };

    const sendPassword = (event) => {
        event.preventDefault();

        if (data.email === '') {
            toast.error('Email address is required.');
            return;
        }

        if (!isValidEmail(data.email)) {
            toast.error('Please enter a valid email.');
            return;
        }

        FlowSRCAPI.SendPassword(data).then((response) => {

            let sendPasswordStatus = response.responseStatus;
            switch (sendPasswordStatus.toLowerCase()) {
                case 'email not found':
                    toast.error('Email not found, unable to send password.');
                    break;
                case 'success':
                    toast.success('Your password has been emailed to ... ' + data.email);
                    break;
                default:
                    toast.error(sendPasswordStatus);
            }

        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0)
            }
            else
                ShowMarketplaceError(e.message, 'profileexisting.js', 'sendPassword()');
        });
    };

    return (
        <div>
            <h3 className='marketplaceFormTitle'>We’re so glad you’ve returned to the NexRep Contractor Marketplace!</h3>
            <h5 className='marketplaceFormSubtitle'>
                Please login to your existing Marketplace account.
                <span className='marketplaceFormSubtitleItalic'> If you don't have an account, <Link className='marketplaceFormLink' to="/register">please click here to join now.</Link></span>
            </h5>
            <div className='form-group'>
                <label className='marketplaceFormFieldLabel'>Email</label>
                <span className="marketplaceFormFieldLabelRequired"> *</span>
                <TextField
                    required
                    fullWidth
                    name='email'
                    type='email'
                    className='mt-1 '
                    value={data.email}
                    onChange={(e) => { handleInputChange(e) }}
                    size='small'
                />
            </div>
            <div className='form-group'>
                <label className='marketplaceFormFieldLabel'>Password</label>
                <span className="marketplaceFormFieldLabelRequired"> *</span>
                <TextField
                    required
                    fullWidth
                    name='password'
                    type='password'
                    className='mt-1'
                    value={data.password}
                    onChange={(e) => { handleInputChange(e) }}
                    size='small'
                />
            </div>
            {
                promptSources ?
                    <div className='form-group'>
                        <label className='marketplaceFormFieldLabel'>Where did you hear about the Marketplace?</label>
                        <span className="marketplaceFormFieldLabelRequired"> *</span>
                        <Select
                            required
                            fullWidth
                            name='webTrafficSourceDetailID'
                            className='mt-1'
                            options={promptSources}
                            value={data.webTrafficSourceDetailID}
                            onChange={(e) => { handleInputChange(e) }}
                            MenuProps={{
                                style: {
                                    maxHeight: 400,
                                },
                            }}
                            size='small'>
                            {promptSources.map((source, index) => (
                                <MenuItem key={index} value={source.WebTrafficSourceDetailID}>{source.SourceName}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    : null
            }
            <div hidden={!showOther} className='form-group'>
                <label className='marketplaceFormFieldLabel'>If Other...</label>
                <span className="marketplaceFormFieldLabelRequired"> *</span>
                <TextField
                    required
                    fullWidth
                    name='webTrafficSourceOther'
                    type='webTrafficSourceOther'
                    className='mt-1'
                    value={data.webTrafficSourceOther}
                    onChange={(e) => { handleInputChange(e) }}
                    size='small'
                />
            </div>
            <div className='gap-2'>
                <button disabled={lock} type='submit' className='marketplaceButton mt-2' onClick={(e) => { loginClick(e) }}>
                    Next
                </button>
                <div className='marketplaceFormFooter'>
                    <label className='text-right mt-2'>
                        Forgot Password? <Link className='marketplaceFormLink' onClick={(e) => { sendPassword(e) }} to='/password'>Click Here</Link>
                    </label>
                    <br></br>
                    <label className='text-right mt-2'>
                        Not a Member? <Link className='marketplaceFormLink' to="/register">Join Now</Link>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ProfileExisting;

ProfileExisting.propTypes = {
    data: PropTypes.any,
    setData: PropTypes.func
};
