import PropTypes from 'prop-types';
import { FormControl, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import moment from 'moment';

const FormText = (props) => {

    const { stepDetails, control } = props;

    function maxLength(value) {

        if (!stepDetails.validateTextMax || stepDetails.fieldType === 'Date' || stepDetails.fieldType === 'Decimal')
            return;

        return value.length <= Number(stepDetails.validateTextMax) || `Please enter no more than ${stepDetails.validateTextMax} characters.`;
    };

    function minLength(value) {

        if (!stepDetails.validateTextMin || stepDetails.fieldType === 'Date' || stepDetails.fieldType === 'Decimal')
            return;

        return value.length >= Number(stepDetails.validateTextMin) || `Please enter at least ${stepDetails.validateTextMin} characters.`;
    };

    function valueIsNumber(value) {

        if (stepDetails.fieldType !== 'Integer')
            return;

        return !isNaN(value) || 'Enter a valid integer.';
    };

    function valueIsDecimal(value) {

        if (stepDetails.fieldType !== 'Decimal')
            return;

        return !isNaN(value) || 'Enter a valid decimal.';
    };

    function valueIsDate(value) {

        if (stepDetails.fieldType !== 'Date')
            return;

        return moment(value, 'MM/DD/YYYY', true).isValid() || 'Enter a valid date.';
    };

    function valueIsTime(value) {

        if (stepDetails.fieldType !== 'Time')
            return;

        return moment(value, 'hh:mm a', true).isValid() || 'Enter a valid time.';
    };

    function valueIsDateTime(value) {

        if (stepDetails.fieldType !== 'DateTime')
            return;

        return moment(value, 'MM/DD/YYYY hh:mm a', true).isValid() || 'Enter a valid date time.';
    };

    return (
        <FormControl fullWidth>
            <label className={[stepDetails.promptLabelClass, (stepDetails.isFlex && stepDetails.promptLabelColumns && stepDetails.promptLabelColumns !== '' ? ' col-' + stepDetails.promptLabelColumns : '')]} >{stepDetails.promptLabel}<span className='marketplaceProcessRequired'>{stepDetails.validateRequired && ' *'}</span></label>
            <Controller
                key={stepDetails.referenceValidate}
                control={control}
                name={stepDetails.referenceValidate}
                defaultValue={stepDetails.fieldValue}
                render={({ field, fieldState: {error} }) => (
                    <>
                        <TextField
                            {...field}
                            fullWidth
                            id={stepDetails.reference}
                            name={stepDetails.reference}
                            multiline={stepDetails.promptValueType.toLowerCase() === 'textarea' ? true : false}
                            className={stepDetails.promptValueClass}
                            rows={stepDetails.promptValueType.toLowerCase() === 'textarea' ? 10 : 1}
                            size='small'
                        />
                        {(() => {
                            if (error && error?.type === 'required')
                                return <label className='marketplaceProcessRequired'>This field is required.</label>
                            else if (error && error?.type === 'minLen')
                                return <label className='marketplaceProcessRequired'>Please enter at least {stepDetails.validateTextMin} characters.</label>
                            else if (error && error?.type === 'maxLen')
                                return <label className='marketplaceProcessRequired'>Please enter no more than {stepDetails.validateTextMax} characters.</label>
                            else if (error && error?.type === 'valueIsNumber')
                                return <label className='marketplaceProcessRequired'>Please enter valid integer.</label>
                            else if (error && error?.type === 'valueIsDecimal')
                                return <label className='marketplaceProcessRequired'>Please enter valid decimal.</label>
                            else if (error && error?.type === 'valueIsDate')
                                return <label className='marketplaceProcessRequired'>Enter a valid date (mm/dd/yyyy).</label>
                            else if (error && error?.type === 'valueIsTime')
                                return <label className='marketplaceProcessRequired'>Enter a valid time (hh:mm am/pm).</label>
                            else if (error && error?.type === 'valueIsDateTime')
                                return <label className='marketplaceProcessRequired'>Enter a valid date/time (mm/dd/yyyy hh:mm am/pm).</label>
                        })()}
                    </>
                )}
                rules={{
                    required: stepDetails.validateRequired,
                    validate:
                    {
                        maxLen: value => maxLength(value),
                        minLen: value => minLength(value),
                        valueIsNumber: value => valueIsNumber(value),
                        valueIsDecimal: value => valueIsDecimal(value),
                        valueIsDate: value => valueIsDate(value),
                        valueIsTime: value => valueIsTime(value),
                        valueIsDateTime: value => valueIsDateTime(value),
                    }
                }}
            />
        </FormControl>
    );

};

export default FormText;

FormText.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    control: PropTypes.any.isRequired
};
