
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { sanitize } from 'dompurify';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { BrowserView, MobileView, isFirefox } from 'react-device-detect';
import { Controller } from 'react-hook-form';

const FormMatrix = (props) => {

    const { stepDetails, getValues, control } = props;
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedLabel, setSelectedLabel] = useState('');


    useEffect(() => {
        let value = getValues(stepDetails.referenceValidate);
        let label = stepDetails.selectValues.filter(function (item) { return item.Value === value; });
        if (label.length > 0) {
            setSelectedValue(label[0].Value);
            setSelectedLabel(label[0].Label);
        }
    }, [])
    const isChecked = (source) => {
        if (source?.Value?.toLowerCase() === stepDetails?.fieldValue?.toLowerCase() || source?.Selected?.toLowerCase() === 'yes') {
            return true;
        }
    };

    const radioOnClick = (event) => {
        setSelectedValue(event.target.value);
    };

    const mobileRadioClick = (event) => {
        setSelectedValue(event.target.value);

        let label = stepDetails.selectValues.filter(function (item) { return item.Value === event.target.value; });
        if (label.length > 0) {
            setSelectedValue(label[0].Value);
            setSelectedLabel(label[0].Label);
        }

    };

    return (
        <Controller
            key={stepDetails.referenceValidate}
            control={control}
            name={stepDetails.referenceValidate}
            defaultValue={stepDetails.fieldValue}
            render={({ field, fieldState: { error } }) => (
                <>
                    <BrowserView>
                        <table className='marketplaceMatrix'>
                            {stepDetails.skipHeaders ?
                                <></>
                                :
                                <thead>
                                    <tr>
                                        <th className='headerTop' width='350px'></th>
                                        {stepDetails.selectValues.map((value, valueIndex) => (
                                            <th key={valueIndex} className='headerTop' dangerouslySetInnerHTML={{ __html: sanitize(value.Label) }}></th>
                                        ))}
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                <tr className='tableRow'>
                                    <th className='headerLeft'>{stepDetails.promptLabel}</th>
                                    {stepDetails.selectValues.map((value, valueIndex) => (
                                        <>
                                            {
                                                <td key={valueIndex}>
                                                    <input
                                                        type='radio'
                                                        onClick={radioOnClick}
                                                        name={stepDetails.reference}
                                                        id={stepDetails.reference + valueIndex}
                                                        key={valueIndex}
                                                        checked={isChecked(value)}
                                                        {...field}
                                                        value={value.Value}
                                                        label={value.Label} />
                                                </td>
                                            }
                                        </>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                        {error && error?.type === "required" && (
                            <label className="marketplaceProcessRequired">This field is required.</label>
                        )}
                    </BrowserView>
                    <MobileView>
                        <Accordion className='marketplaceMatrixMobile'>
                            <AccordionSummary expandIcon={<ExpandMore fontSize='large' style={{ paddingLeft: '5px' }} />}>
                                <div className='marketplaceMatrixMobileAccordionTitle'>
                                    <div className={stepDetails.promptLabelClass}>
                                        {stepDetails.promptLabel}
                                    </div>
                                    <div className='marketplaceMatrixMobileAccordionSubtitle'>
                                        {error && error?.type === "required" ?
                                            <label className="marketplaceProcessRequired">This field is required.</label>
                                            :
                                            <label>{selectedLabel}</label>
                                        }
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {stepDetails.selectValues.map((value, valueIndex) => (
                                    <div key={valueIndex} className='form-check marketplaceProcessRadioButtonFieldSet mobile'>
                                        <input
                                            type='radio'
                                            onClick={mobileRadioClick}
                                            name={stepDetails.reference}
                                            id={stepDetails.reference + valueIndex}
                                            key={valueIndex}
                                            checked={isChecked(value)}
                                            {...field}
                                            value={value.Value} />
                                        <label dangerouslySetInnerHTML={{ __html: sanitize(value.Label) }}></label>
                                    </div>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    </MobileView>              
                </>
            )}
            rules={{
                required: stepDetails.validateRequired
            }}
        />
    );
};

export default FormMatrix;

FormMatrix.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    getValues: PropTypes.any,
    control: PropTypes.any
};