import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import logo from '../../../assets/logoNexRep.jpg'
import { logout } from '../../../redux/core/corereducer';

export default function MarketplaceMenu(props) {

    const {onRefresh} = props;
    const isAuthenticated = useSelector(state => state.core.isAuthenticated);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logOut = () => {
        dispatch(logout());
        navigate("/");
        onRefresh(true);
    };

    return (
        <header>
            <Navbar className='marketplaceHeader navbar-expand-sm navbar-toggleable-sm ng-white border-bottom' >
                <NavbarBrand href='https://nexrep.com'><img className='marketplaceLogo' src={logo} /> </NavbarBrand>
                <ul className='navbar-nav flex-grow'>
                    {isAuthenticated ?
                        <NavItem>
                            {<NavLink tag={Link} className='text-dark' to='/login' onClick={() => { logOut() }}>Log Out</NavLink>}
                        </NavItem>
                        : null
                    }
                </ul>
            </Navbar>
        </header>
    );
}

MarketplaceMenu.propTypes = {
    onRefresh: PropTypes.any
};

