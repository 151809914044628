import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import { FormatDate } from '../library/libraryutil';

export function ShowMarketplaceError(errorDesc, sourceFile, errorFunction) {
    toast.error('Error: ' + errorDesc + '\n' + '\n' + 'Date: ' + FormatDate(new Date()) + '\n' + sourceFile + '\n' + errorFunction);
};

export function ShowMarketplaceMessage(type, message) {
    if (type === 'error')
        toast.error(message);
    else if (type === 'warning')
        toast.warning(message);
    else
        toast.success(message);
};

export function GetCookie(cname) {

    try {

        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return '';

    }
    catch (e) {
        return null;
    }

};


export function QueryStringToJSON() {
    var pairs = window.location.search.slice(1).split('&');
    var result = {};

    pairs.forEach(function (pair) {
        pair = pair.split('=');
        result[pair[0].toLowerCase()] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
};