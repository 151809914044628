
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { MediaTime } from '../../../redux/process/processreducer';

const FormAudio = (props) => {

    const { step } = props;
    const audioRef = useRef(null);
    const mediaTime = useSelector((state) => state.process.mediaTime);
    const dispatch = useDispatch();

    let mediaType = step.stepDetails.MediaType;
    let mediaSource = step.stepDetails.MediaSource;
    let mediaHeight = step.stepDetails.MediaHeight;
    let mediaAutoplay = step.stepDetails.MediaAutoplay === 'Yes' ? true : false;

    useEffect(() => {
        if (mediaTime > 0)
            audioRef.current.currentTime = mediaTime;
        const interval = setInterval(async () => {
            const elapsed_sec = audioRef.current.currentTime;
            dispatch(MediaTime(elapsed_sec));
        }, 100);

        return () => {
            dispatch(MediaTime(0));
            clearInterval(interval);
        };
    }, [])


    if (step.stepDetails.MediaSource.includes('mp3')) {
        return (
            <audio id='marketplaceProcessAudio'  className='marketplaceProcessAudio mt-2' ref={audioRef} height={mediaHeight} style={{ width: '100%' }} autoPlay={mediaAutoplay} controls controlsList='nodownload' >
                {mediaType === 'URL' ?
                    <source src={mediaSource} />
                    :
                    <source type='audio/mp3' src={'data:audio/mp3;base64,' + mediaSource} />
                }
            </audio>
        );
    }
    else {
        return (
            <iframe id='marketplaceProcessAudio'  src={step.stepDetails.MediaSource} ref={audioRef} height={mediaHeight} style={{ width: '100%' }}></iframe>
        );
    }
};

export default FormAudio;

FormAudio.propTypes = {
    step: PropTypes.any.isRequired,
};
