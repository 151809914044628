import { Link } from "react-router-dom";

const PippletConfirmation = () => {

    return (
        <div className='marketplaceFormContainer'>
            <form id='marketplaceForm' className='marketplaceForm' style={{ height: '240px' }}>
                <div className='marketplaceFormContent'>
                    <h3 className='marketplaceFormTitle'>NexRep Contractor Marketplace</h3>
                    <p>
                        Thank you for taking the time to complete the Pipplet test! We appreciate your effort and commitment to showcasing your skills.
                        Your participation is valuable to us, and we look forward to reviewing your results.
                    </p>
                    <div className='gap-2 mt-3'>
                        <div className='marketplaceFormFooter'>
                            <label className='text-right mt-2'>
                                Already a member? <Link className='marketplaceFormLink' to="/login">Sign In</Link>
                            </label>
                            <br></br>
                            <label className='text-right mt-2'>
                                Not a Member? <Link className='marketplaceFormLink' to="/register">Join Now</Link>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default PippletConfirmation;