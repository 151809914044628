import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { setTimer } from '../../../redux/process/processreducer';

const FormTimer = (props) => {

    const { stepDetails, formState, onDisable } = props;
    const dispatch = useDispatch();
    const [timerSeconds, setTimerSeconds] = useState(0);
    const timerInterval = useRef();
    const timerTime = useSelector((state) => state.process.intervalTime);

    let totalTimerSeconds = 0;
    if (timerTime && timerTime < 0)
        totalTimerSeconds = 0;
    else if (timerTime && timerTime === 0 && stepDetails.formTimerTime)
        totalTimerSeconds = (stepDetails.formTimerType.toLowerCase() === 'seconds' ? Number(stepDetails.formTimerTime) : Number(stepDetails.formTimerTime) * 60);
    else if (stepDetails.fieldValue !== '')
        totalTimerSeconds = timerTime && timerTime > 0 ? timerTime : Number(stepDetails.fieldValue);
    else
        totalTimerSeconds = timerTime && timerTime > 0 ? timerTime : (stepDetails.formTimerType.toLowerCase() === 'seconds' ? Number(stepDetails.formTimerTime) : Number(stepDetails.formTimerTime) * 60);

    const startTimer = (duration) => {

        let timer = duration, minutes, seconds, currentTotalSeconds;
        timerInterval.current = setInterval(() => {
            try {
                seconds = parseInt(timer % 60, 10);
                minutes = parseInt(timer / 60, 10);
                currentTotalSeconds = timer;
                minutes = minutes < 10 ? '0' + minutes : minutes;
                seconds = seconds < 10 ? '0' + seconds : seconds;
                document.getElementById('timer_' + stepDetails.reference).innerHTML = minutes + ':' + seconds;
                setTimerSeconds(currentTotalSeconds);
                dispatch(setTimer(currentTotalSeconds));
                if (--timer < 0) {
                    clearInterval(timerInterval.current);
                    document.getElementById('timer_' + stepDetails.reference).innerHTML = '00:00';
                    document.getElementById(stepDetails.reference).value = 0;
                    dispatch(setTimer(-1));

                    if (stepDetails.formTimerOnEnd && stepDetails.formTimerOnEnd === 'Disable') {
                        let processBusy = document.getElementById('divProcessBusy');
                        processBusy.style.display = 'block';
                        processBusy.innerHTML = '<span id=\"marketplaceFormTimerSpan\" class=\"marketplaceFormTimerSpan\">Time is up, please click continue.</span>';
                        $('#marketplaceProcess-body').removeClass('marketplaceProcess-body');
                        $('#marketplaceProcess-body').addClass('hidden');

                        $.each($('#marketplaceProcessAudio'), function () {
                            $(this).remove();
                        });
                        $.each($('#marketplaceProcessVideo'), function () {
                            $(this).remove();
                        });
                        onDisable(true);
                    }
                }

            } catch { clearInterval(timerInterval.current); }
        }, 1000);
    };

    useEffect(() => {
        setTimerSeconds(totalTimerSeconds);
        startTimer(totalTimerSeconds);
        return () => {

            if ($.isEmptyObject(formState.errors)) {
                dispatch(setTimer(0));
            }
            clearInterval(timerInterval.current);
        }

    }, []);


    return (
        <div className='marketplaceProcessTimer' style={stepDetails.formTimed === 'Hidden' ? { display: 'none' } : null}>
            <div>
                <span id={'timer_' + stepDetails.reference} ></span>
                <input type='text' hidden style={{ color: '#c10000', fontSize: '25px' }} value={totalTimerSeconds} name={stepDetails.reference} id={stepDetails.reference} />
            </div>
        </div>
    );
};

export default FormTimer;

FormTimer.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    formState: PropTypes.any,
    onDisable: PropTypes.any
};

FormTimer.defaultProps = {
    onDisable: false,
}