import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userSessionGUID: '',
    userSessionID: 0,
    agentProfileGUID: '',
    agentProfileID: 0,
    marketplaceSessionGUID: '',
    promptSources: [],
    isAuthenticated: false
};

export const CoreReducer = createSlice({
    name: 'Core',
    initialState,
    reducers: {
        AgentProfile: (state, action) => {
            state.agentProfileGUID = action.payload.agentProfileGUID;
            state.agentProfileID = action.payload.agentProfileID
        },
        UserSessionGUID: (state, action) => {
            state.userSessionGUID = action.payload;
        },
        UserSessionID: (state, action) => {
            state.userSessionID = action.payload;
        },
        MarketplaceSessionGUID: (state, action) => {
            state.marketplaceSessionGUID = action.payload;
        },
        PromptSources: (state, action) => {
            state.promptSources = action.payload;
        },
        login: (state) => {
            state.isAuthenticated = true;
        },
        logout: (state) => {

        }
    }
});

export const { AgentProfile, UserSessionGUID, UserSessionID, MarketplaceSessionGUID, PromptSources, login, logout } = CoreReducer.actions;

export default CoreReducer.reducer;