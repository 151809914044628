import axios from "axios";
import { SOCRATESAPIURL } from "../../config/constant";


const CoreAPI = ({

    Initialize_MarketplaceSession: function (data) {

        try {

            return axios({
                url: `${SOCRATESAPIURL}/SocratesMarketplace/Initialize`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'InitializedBy': "Marketplace Plugin",
                    'QueryStringSource': data.src,
                    'QueryStringSourceDetail': data.srcd,
                    'QueryStringProgramID': data.prog,
                    'QueryStringSourceDetailID': data.srcdid,
                    'Attempts': data.attempts,
                    'PageURL': encodeURIComponent(window.location),
                    'ReferrerURL': encodeURIComponent(window.referrer)
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    }
});

export default CoreAPI;