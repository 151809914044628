import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ProcessAPI from '../../api/process/processapi';
import Process from '../process/process';
import { ShowMarketplaceError } from '../../utils/core/coreutil';
import MarketplaceSpinner from '../../components/shared/marketplacespinner/marketplacespinner';
import { useDispatch } from 'react-redux';
import { SetFormStep } from '../../redux/process/processreducer';


const Assessment = () => {

    const marketplaceSessionGUID = useSelector((state) => state.core.marketplaceSessionGUID);
    const agentProfileGUID = useSelector((state) => state.core.agentProfileGUID);
    const agentProfileID = useSelector((state) => state.core.agentProfileID);
    const assessmentID = useSelector((state) => state.process.assessmentID);
    const processID = useSelector((state) => state.process.processID);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [data, setData] = useState({
        marketplaceSessionGUID: marketplaceSessionGUID,
        agentProfileGUID: agentProfileGUID,
        agentProfileID: agentProfileID,
        processID: processID > 0 ? processID : location.state.processID,
        processCriteria:
        {
            'assessmentID': assessmentID > 0 ? assessmentID : location.state.assessmentID,
            'agentProfileID': agentProfileID
        },
        processUI: []
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        assessmentOnLoad();
    }, []);

    const assessmentOnLoad = () => {

        ProcessAPI.Begin(data).then((response) => {
            setData({
                ...data,
                processUI: response.processUI,
                processSessionGUID: response.processSessionGUID
            });

            let step = response.processUI.find(value => value.stepType.includes('FormSend'));
            if (step)
                dispatch(SetFormStep(step))

            setLoading(false);
        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0);
            }
            else
                ShowMarketplaceError(e.message, 'assessment.js', 'assessmentOnLoad()');
        });
    };

    return (
        <div>
            {!loading ?
                <Process props={data} />
                : <MarketplaceSpinner hidden={false} />
            }
        </div>
    );
}

export default Assessment;
