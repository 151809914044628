import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "../components/shared/protectedroute";
import ProfileEdit from "../pages/flowsrc/profileedit";
import ResidancyUpdate from "../pages/flowsrc/residencyupdate";
import AffirmationUpdate from "../pages/flowsrc/affirmationupdate";
import AssessmentUpdate from "../pages/assessment/assessmentupdate";
import Assessment from "../pages/assessment/assessment";
import Gateway from "../pages/gatewaybgc/gateway";
import ScheduleAvailability from "../pages/schedule/scheduleavailability";
import ScheduleConfirm from "../pages/schedule/scheduleconfirm";
import ScheduleCancel from "../pages/schedule/schedulecancel";
import Login from "../pages/core/login";
import PippletConfirmation from "../pages/custom/pippletconfirmation";
import ClassAvailability from "../pages/class/classavailibility";

const MarketplaceRoutes = ({ values }) => {

    return (
        <Routes>
            <Route path='/profileedit' element={
                <ProtectedRoute>
                    <ProfileEdit path='/profileedit' props={values} />
                </ProtectedRoute>
            } />
            <Route path='/residancyupdate' element={
                <ProtectedRoute>
                    <ResidancyUpdate path='/residancyupdate' props={values} />
                </ProtectedRoute>
            } />
            <Route path='/affirmationupdate' element={
                <ProtectedRoute>
                    <AffirmationUpdate path='/affirmationupdate' props={values} />
                </ProtectedRoute>
            } />
            <Route path='/assessmentupdate' element={
                <ProtectedRoute>
                    <AssessmentUpdate path='/assessmentupdate' props={values} />
                </ProtectedRoute>
            } />
            <Route path='/assessment' element={
                <ProtectedRoute>
                    <Assessment path='/assessment' props={values} />
                </ProtectedRoute>
            } />
            <Route path='/gateway' element={
                <ProtectedRoute>
                    <Gateway path='/gateway' props={values} />
                </ProtectedRoute>
            } />
            <Route path='/scheduleavailability' element={
                <ProtectedRoute>
                    <ScheduleAvailability path='/scheduleavailability' />
                </ProtectedRoute>
            } />
            <Route path='/scheduleconfirm' element={
                <ProtectedRoute>
                    <ScheduleConfirm path='/scheduleconfirm' />
                </ProtectedRoute>
            } />
            <Route path='/schedulecancel' element={
                <ProtectedRoute>
                    <ScheduleCancel path='/schedulecancel' />
                </ProtectedRoute>
            } />
             <Route path='/classavailibility' element={
                <ProtectedRoute>
                    <ClassAvailability path='/classavailibility' />
                </ProtectedRoute>
            } />

            <Route path='login' element={<Login props={values} />} />
            <Route path='register' element={<Login props={values} />} />
            <Route path='password' element={<Login props={values} />} />
            <Route path='confirmation' element={<PippletConfirmation />} />
        </Routes>
    );
};


export default MarketplaceRoutes;