import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

import { ShowMarketplaceError } from '../../utils/core/coreutil';
import FlowSRCAPI from '../../api/flowsrc/flowsrcapi';

const AffirmationUpdate = (props) => {
    const marketplaceSessionGUID = useSelector((state) => state.core.marketplaceSessionGUID);
    const agentProfileGUID = useSelector((state) => state.core.agentProfileGUID);
    const agentProfileID = useSelector((state) => state.core.agentProfileID);
    const [data, setData] = useState({
        marketplaceSessionGUID: marketplaceSessionGUID,
        agentProfileGUID: agentProfileGUID,
        agentProfileID: agentProfileID,
        affirmationText: 'THIS IS NOT AN EMPLOYMENT OPPORTUNITY. \n I understand that by joining the NexRep Contractor Marketplace I will not be an employee of NexRep and any services rendered will be provided on an independent contractor basis. \n Additionally, I understand that Marketplace contractors must be at least 18 years of age and legally able to provide services in the United States.'
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [lock, setLock] = useState(false);

    useEffect(() => {
        affirmationUpdateOnLoad();
    }, []);

    const affirmationUpdateOnLoad = () => {

        FlowSRCAPI.AffirmationCheck(data).then((response) => {

            let checkaffirmationStatus = response.responseStatus;

            switch (checkaffirmationStatus.toLowerCase()) {
                case 'not blocked':
                    navigate('/assessmentupdate', { props: props })
                    break;
                case 'blocked':
                    break;
                default:
                    toast.error(checkaffirmationStatus);
            }
            setLoading(false);
        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0);
            }
            else
                ShowMarketplaceError(e.message, 'affirmationupdate.js', 'affirmationUpdateOnLoad()');

            setLoading(false);

        });

    };

    const residancyCheckClick = (e) => {

        e.preventDefault();

        setLock(true);

        FlowSRCAPI.AffirmationUpdate(data, 'Yes').then((response) => {

            let checkResidencyStatus = response.responseStatus;

            switch (checkResidencyStatus.toLowerCase()) {
                case 'success':
                    navigate('/assessmentupdate', { props: props })
                    break;
                default:
                    toast.error(checkResidencyStatus);
            }
            setLock(false);
        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0);
            }
            else
                ShowMarketplaceError(e.message, 'residancyupdate.js', 'residancyUpdateOnLoad()');

            setLock(false);
        });

    };

    const cancelClick = (e) => {

        e.preventDefault();

        setLock(true);
        FlowSRCAPI.AffirmationUpdate(data, 'No').then((response) => {

            let checkResidencyStatus = response.responseStatus;

            switch (checkResidencyStatus.toLowerCase()) {
                case 'success':
                    toast.error('To join the Marketplace, you must agree to these terms.');
                    break;
                default:
                    toast.error(checkResidencyStatus);
            }

            setLock(false);

        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0);
            }
            else
                ShowMarketplaceError(e.message, 'residancyupdate.js', 'residancyUpdateOnLoad()');
            
            setLock(false);
        });

    };

    return (
        <>
            {!loading ?
                <div className='marketplaceFormContainer'>
                    <form className='marketplaceForm' style={{ height: '340px' }}>
                        <div className='marketplaceFormContent'>
                            <h3 className='marketplaceFormTitle'>NexRep Contractor Marketplace</h3>
                            <div id='affirmationText'>
                                <p><b>THIS IS NOT AN EMPLOYMENT OPPORTUNITY.</b></p>
                                <p>I understand that by joining the NexRep Contractor Marketplace I will not be an employee of NexRep and any services rendered will be provided on an independent contractor basis.</p>
                                <p>Additionally, I understand that Marketplace contractors must be at least 18 years of age and legally able to provide services in the United States.</p>
                            </div>
                            <div className='gap-2 mt-3'>
                                <button disabled={lock} type='submit' className='marketplaceButton mt-2' onClick={(e) => { residancyCheckClick(e) }}>
                                    Agree
                                </button>
                                <button disabled={lock} type='submit' className='marketplaceButton mt-2' onClick={(e) => { cancelClick(e) }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                : null
            }
        </>
    );
};

export default AffirmationUpdate;
