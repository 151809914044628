import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { hotjar } from 'react-hotjar';
import $ from 'jquery';

import { ShowMarketplaceError } from '../../utils/core/coreutil';
import FlowSRCAPI from '../../api/flowsrc/flowsrcapi';


const ProfileNew = (props) => {

    const { data, setData } = props;
    const promptSources = useSelector((state) => state.core.promptSources);
    const [showOther, setShowOther] = useState(false);
    const [lock, setLock] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {

        if (hotjar.initialized()) {
            hotjar.stateChange('/profilenew');
        }

        if (!promptSources)
            $('.marketplaceForm').css('height', '500px');

    }, []);

    const registerClick = (event) => {
        event.preventDefault();

        if (data.email === '' || data.firstname === '' || data.lastname === '') {
            toast.error('All fields are required.');
            return;
        }

        if (data.webTrafficSourceDetailID === 0 && promptSources) {
            toast.error('All fields are required.');
            return;
        }

        else if (data.webTrafficSourceDetailID === 100554 && data.webTrafficSourceOther === '') {
            toast.error('All fields are required.');
            return;
        }

        if (!isValidEmail(data.email)) {
            toast.error('Please enter a valid email.');
            return;
        }

        setLock(true);

        FlowSRCAPI.ProfileNew(data).then((response) => {

            let existingProfileStatus = response.responseStatus;

            switch (existingProfileStatus.toLowerCase()) {
                case 'new profile':
                    navigate('/password', { props: props })
                    break;
                case 'existing profile - password not set':
                    navigate('/password', { props: props })
                    break;
                case 'existing profile':
                    sendPassword(event);
                    break;
                default:
                    toast.error(existingProfileStatus);
            }

            setLock(false);

        }).catch((e) => {
            ShowMarketplaceError(e.message, 'profilenew.js', 'registerClick()');
            setLock(false);
        });

    };

    const handleInputChange = (e) => {
        e.preventDefault();

        if (e.target.name === 'webTrafficSourceDetailID' && e.target.value === 100554) {
            setShowOther(true);
            $('.marketplaceForm').css('height', '640px')
        } else if (e.target.name === 'webTrafficSourceDetailID' && e.target.value !== 100554) {
            setShowOther(false);
            $('.marketplaceForm').css('height', '580px')
        }

        setData(prevState => {
            return { ...prevState, [e.target.name]: e.target.value }
        })
    };

    const sendPassword = (event) => {
        event.preventDefault();

        if (data.email === '') {
            toast.error('Email address is required.');
            return;
        }

        if (!isValidEmail(data.email)) {
            toast.error('Please enter a valid email.');
            return;
        }

        FlowSRCAPI.SendPassword(data).then((response) => {

            let sendPasswordStatus = response.responseStatus;
            switch (sendPasswordStatus.toLowerCase()) {
                case 'email not found':
                    toast.error('Email not found, unable to send password.');
                    break;
                case 'success':
                    toast.success('Your password has been emailed to ... ' + data.email);
                    navigate('/password');
                    break;
                default:
                    toast.error(sendPasswordStatus);
            }

        }).catch((e) => {
            ShowMarketplaceError(e.message, 'profilenew.js', 'sendPassword()');
        });
    };

    const isValidEmail = (email) => {

        let emailParts = email.split('@');

        if (emailParts.length !== 2) {
            return false;
        }

        let emailName = emailParts[0];
        let emailDomain = emailParts[1];

        if (emailName.length < 1 || emailDomain.length < 1) {
            return false;
        }

        let domainParts = emailDomain.split('.');

        if (domainParts.length < 2) {
            return false;
        }

        for (let i = 0; i < domainParts.length; i++) {
            if (domainParts[i].length === 0)
                return false;
        }

        return true;
    };

    return (
        <div>
            <h3 className='marketplaceFormTitle'>We’re so glad you’ve decided to join the NexRep Contractor Marketplace!</h3>
            <p className='marketplaceFormSubtitle'>
                To get started with the registration process, please provide your name and email address. If you already have a Marketplace account, <Link className='marketplaceFormLink' to="/login">please click here to sign in.</Link>
            </p>
            <div className='form-group'>
                <label className='marketplaceFormFieldLabel'>First Name</label>
                <span className="marketplaceFormFieldLabelRequired"> *</span>
                <TextField
                    name='firstname'
                    className='form-control mt-1'
                    value={data.firstname}
                    onChange={(e) => { handleInputChange(e) }}
                    size='small'
                />
            </div>
            <div className='form-group'>
                <label className='marketplaceFormFieldLabel'>Last Name</label>
                <span className="marketplaceFormFieldLabelRequired"> *</span>
                <TextField
                    name='lastname'
                    className='form-control mt-1'
                    value={data.lastname}
                    onChange={(e) => { handleInputChange(e) }}
                    size='small'
                />
            </div>
            <div className='form-group'>
                <label className='marketplaceFormFieldLabel'>Email</label>
                <span className="marketplaceFormFieldLabelRequired"> *</span>
                <TextField
                    name='email'
                    className='form-control mt-1'
                    value={data.email}
                    onChange={(e) => { handleInputChange(e) }}
                    size='small'
                />
            </div>
            {
                promptSources ?
                    <div className='form-group'>
                        <label className='marketplaceFormFieldLabel'>Where did you hear about the Marketplace?</label>
                        <span className="marketplaceFormFieldLabelRequired"> *</span>
                        <Select
                            required
                            fullWidth
                            name='webTrafficSourceDetailID'
                            className='mt-1'
                            options={promptSources}
                            value={data.webTrafficSourceDetailID}
                            onChange={(e) => { handleInputChange(e) }}
                            MenuProps={{
                                style: {
                                    maxHeight: 400,
                                },
                            }}
                            size='small'>
                            {promptSources.map((source, index) => (
                                <MenuItem key={index} value={source.WebTrafficSourceDetailID}>{source.SourceName}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    : null
            }
            <div hidden={!showOther} className='form-group'>
                <label className='marketplaceFormFieldLabel'>If Other...</label>
                <span className="marketplaceFormFieldLabelRequired"> *</span>
                <TextField
                    required
                    fullWidth
                    name='webTrafficSourceOther'
                    type='webTrafficSourceOther'
                    className='mt-1'
                    value={data.webTrafficSourceOther}
                    onChange={(e) => { handleInputChange(e) }}
                    size='small'
                />
            </div>
            <div className='gap-2 mt-3'>
                <button disabled={lock} type='submit' className='marketplaceButton mt-2' onClick={(e) => { registerClick(e) }}>
                    Next
                </button>
                <div className='marketplaceFormFooter'>
                    <label className='text-right mt-3'>
                        Already a member? <Link className='marketplaceFormLink' to="/login">Sign In</Link>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ProfileNew;



ProfileNew.propTypes = {
    data: PropTypes.any,
    setData: PropTypes.any
};
