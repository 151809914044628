import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import CoreReducer from './core/corereducer'
import FlowSRCReducer from './flowsrc/flowsrcreducer'
import ProcessReducer from './process/processreducer'

const combinedReducer = combineReducers({
    core: CoreReducer,
    process: ProcessReducer,
    flowsrc: FlowSRCReducer
  });
  
  const rootReducer = (state, action) => {
    if (action.type=== 'Core/logout') {
      state = undefined;
    }
    return combinedReducer(state, action);
  };

const MarketplaceStore = configureStore({
    reducer: rootReducer,
})

export default MarketplaceStore