
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import $ from 'jquery';

const FormBestWorst = (props) => {

    const { stepDetails, errors, register, setValue } = props;
    let i = 0;

    $(document).on('click', '#' + stepDetails.referenceJquery + ' tbody tr input[type=radio]', function () {
        $('input.' + this.className).not($(this)).each(function () {
            this.checked = false;
        })
    });

    const RadioButtonSet = ({ valueIndex, value }) => {

        return (
            <>
                <td>
                    <input
                        type='radio'
                        name={stepDetails.reference + '~|~' + valueIndex}
                        className={stepDetails.referenceJquery + 0}
                        onChange={() => { setValue(stepDetails.referenceValidate, true) }}
                        key={valueIndex}
                        value={value.Value + '~|~Best'} />
                </td>
                <td>
                    <input
                        type='radio'
                        name={stepDetails.reference + '~|~' + valueIndex}
                        className={stepDetails.referenceJquery + 1}
                        onChange={() => { setValue(stepDetails.referenceValidate, true) }}
                        key={valueIndex}
                        value={value.Value + '~|~Worst'} />
                </td>
            </>
        )
    };

    return (
        <>
            <label className={stepDetails.promptLabelClass + ' ' + (stepDetails.isFlex && stepDetails.promptLabelColumns && stepDetails.promptLabelColumns !== '' ? ' col-' + stepDetails.promptLabelColumns : '')} >{stepDetails.promptLabel}<span className='marketplaceProcessRequired'>{stepDetails.validateRequired && ' *'}</span></label>
            <table className='marketplaceBestWorst' id={stepDetails.referenceJquery}>
                <thead>
                    <tr>
                        <th className='headerTop' width={isMobile ? '60%' : '70%'}></th>
                        <th className='headerTop'>Best</th>
                        <th className='headerTop'>Worst</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        stepDetails.selectValues.map((value, valueIndex) => (
                            <>
                                {
                                    <>
                                        <tr key={valueIndex} className='tableRow'>
                                            <th className='headerLeft'>{value.Label}</th>
                                            <RadioButtonSet valueIndex={valueIndex} value={value} />
                                        </tr>
                                    </>
                                }
                            </>
                        ))
                    }
                </tbody>
            </table>
            {errors && errors[stepDetails.referenceValidate]?.type === "required" && (
                <label className="marketplaceProcessRequired">This field is required.</label>
            )}
        </>
    );
};

export default FormBestWorst;

FormBestWorst.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    errors: PropTypes.any,
    register: PropTypes.any,
    setValue: PropTypes.any
};