
import PropTypes from 'prop-types';
import { FormControl } from "@mui/material";
import { ReactSortable } from 'react-sortablejs';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

const FormSortable = (props) => {

    const { stepDetails, control } = props;

    const [values, setValues] = useState(stepDetails.selectValues);

    useEffect(() => {

        let fieldValues = [];

        if (stepDetails.fieldValue.length > 0) {

            let valuesArray = JSON.parse(stepDetails.fieldValue);

            for (let i = 0; i < valuesArray.length; i++) {
                fieldValues.push(valuesArray[i].split('~|~')[0])
            }

            if (fieldValues.length > 0)
                setValues(fieldValues);

        }

    });

    return (
        <FormControl fullWidth>
            <label className={[stepDetails.promptLabelClass, (stepDetails.isFlex && stepDetails.promptLabelColumns && stepDetails.promptLabelColumns !== '' ? ' col-' + stepDetails.promptLabelColumns : '')]} >{stepDetails.promptLabel}<span className='marketplaceProcessRequired'>{stepDetails.validateRequired && ' *'}</span></label>
            <Controller
                key={stepDetails.referenceValidate}
                control={control}
                name={stepDetails.referenceValidate}
                defaultValue={stepDetails.fieldValue}
                render={({ field, fieldState: { error } }) => (
                    <>
                        <ReactSortable
                            className={'marketplaceProcessSortable ' + stepDetails.promptValueClass}
                            list={values}
                            setList={setValues}
                            animation={200}
                            delay={2}
                            ghostClass='sortable-ghost'>
                            {values.map((item, index) => (
                                <div className='item' key={index}>
                                    <span id='badge' style={{ padding: '2px 8px', marginBottom: '5px', background: '#007ac0', borderRadius: '40px' }} className='badge badge-pill badge-primary rank'>{index + 1}</span>
                                    <span style={{ paddingLeft: '20px' }}>{item?.Label}</span>
                                    <input {...field} id={stepDetails.reference} name={stepDetails.reference} value={item?.Value} type='hidden' />
                                </div>
                            ))}
                        </ReactSortable>
                        {error && error?.type === 'required' && (
                            <label className={'marketplaceProcessRequired ' + (stepDetails.isFlex ? 'flex-label' : '')}>This field is required.</label>
                        )}
                    </>
                )}
                rules={{
                    required: stepDetails.validateRequired
                }}
            />
        </FormControl>
    );

};


export default FormSortable;

FormSortable.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    control: PropTypes.any,
};
