import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';

const FormHTML = (props) => {

    const { step } = props;

    return (
        <div dangerouslySetInnerHTML={{ __html: sanitize(step.stepDetails.FormHTML) }} />
    );

};

export default FormHTML;

FormHTML.propTypes = {
    step: PropTypes.any.isRequired,
};
