import { Backdrop, Box, CircularProgress, styled } from '@mui/material';
import NexRepLogo from '../../../assets/iconNexRep.png'

const StyledLoading = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        width: 'auto',
        height: '25px'
    },
    '& .circleProgress': {
        position: 'absolute',
        left: -7,
        right: 0,
        top: 'calc(50% - 18px)'
    }
});

const MarketplaceSpinner = ({ hidden, errorMessage }) => {

    const backdropStyle = {
        background: 'transparent',
        position: 'absolute'
    };

    return (
        <>
            {!hidden ?
                null
                :
                <div className='marketplaceFormContainer'>
                    <form className='marketplaceForm' style={{ height: '450px' }}>
                        <div className='marketplaceFormContent'>
                            <h3 className='marketplaceFormTitle'>NexRep Contractor Marketplace</h3>
                            <Backdrop style={backdropStyle} open>
                                <StyledLoading>
                                    <Box>
                                        {/* <img style={{ width: '35px', height: '35px' }} src={NexRepLogo} alt="" /> */}
                                        <CircularProgress style={{ width: '60px', height: '60px', position: 'absolute', top: '43.5%', left: '46.5%' }} className="circleProgress" />
                                    </Box>
                                </StyledLoading>
                            </Backdrop>
                        </div>
                    </form>
                </div>
            }
        </>
    );


};

export default MarketplaceSpinner;