import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'

import { ShowMarketplaceError } from '../../utils/core/coreutil';
import ScheduleAPI from '../../api/schedule/scheduleapi';
import MarketplaceSpinner from '../../components/shared/marketplacespinner/marketplacespinner';

const ScheduleCancel = (props) => {
    const location = useLocation()
    const marketplaceSessionGUID = useSelector((state) => state.core.marketplaceSessionGUID);
    const agentProfileGUID = useSelector((state) => state.core.agentProfileGUID);
    const agentProfileID = useSelector((state) => state.core.agentProfileID);
    const [data] = useState({
        marketplaceSessionGUID: marketplaceSessionGUID,
        agentProfileGUID: agentProfileGUID,
        agentProfileID: agentProfileID,
        scheduledDate: location.state.startDate,
        scheduledTime: location.state.scheduleInterval,
        timeZone: location.state.timeZone,
        existingCallDateTime: location.state?.existingCallDateTime ? location.state?.existingCallDateTime : '',
        existingSourcingScheduleID: location.state?.existingSourcingScheduleID ? location.state.existingSourcingScheduleID : 0
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [formHeader, setFormHeader] = useState('');
    const [lock, setLock] = useState(false);

    useEffect(() => {
        scheduleCancelOnLoad();
        setLoading(false);
    }, []);

    const scheduleCancelOnLoad = () => {
        setFormHeader(data.existingCallDateTime);
    };

    const scheduleCancelClick = (e) => {

        e.preventDefault();

        setLock(true);

        ScheduleAPI.ScheduleCancel(data).then((response) => {

            let scheduleConfirmStatus = response;

            switch (scheduleConfirmStatus.responseStatus.toLowerCase()) {
                case 'success':
                    navigate('/gateway');
                    break;
                default:
                    toast.error(scheduleConfirmStatus);
            }

            setLoading(false);
            setLock(false);

        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0)
            }
            else
                ShowMarketplaceError(e.message, 'schedulecancel.js', 'scheduleCancelClick()');
            setLock(false);
        });

    };

    const onCancelClick = () => {
        navigate('/gateway');
    };

    return (
        <>
            {!loading ?
                <div className='marketplaceFormContainer'>
                    <form className='marketplaceForm' style={{ height: '200px' }}>
                        <div className='marketplaceFormContent'>
                            <h3 className='marketplaceFormTitle'>NexRep Contractor Marketplace</h3>
                            <p>Are you sure you want to cancel your scheduled call on <b style={{ color: 'red' }}>{formHeader}</b>?</p>
                            <div className='gap-2 mt-3'>
                                <button disabled={lock} type='submit' className='marketplaceButton mt-2' onClick={(e) => { scheduleCancelClick(e) }}>
                                    Yes
                                </button>
                                <button type='submit' className='marketplaceButton mt-2' onClick={(e) => { onCancelClick(e) }}>
                                    No
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                : <MarketplaceSpinner hidden={false} />
            }
        </>
    );
};

export default ScheduleCancel;

ScheduleCancel.propTypes = {
    startDate: PropTypes.any,
    timeZone: PropTypes.any,
    scheduleInterval: PropTypes.any
};
