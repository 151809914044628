import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { hotjar } from 'react-hotjar';

import { ShowMarketplaceError } from '../../utils/core/coreutil';
import { MenuItem, Select, TextField } from '@mui/material';
import FlowSRCAPI from '../../api/flowsrc/flowsrcapi';
import MarketplaceSpinner from '../../components/shared/marketplacespinner/marketplacespinner';

const ResidancyUpdate = (props) => {
    const marketplaceSessionGUID = useSelector((state) => state.core.marketplaceSessionGUID);
    const agentProfileGUID = useSelector((state) => state.core.agentProfileGUID);
    const agentProfileID = useSelector((state) => state.core.agentProfileID);
    const [data, setData] = useState({
        marketplaceSessionGUID: marketplaceSessionGUID,
        agentProfileGUID: agentProfileGUID,
        agentProfileID: agentProfileID,
        country: '',
        countryOther: '',
        state: ''

    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [lock, setLock] = useState(false);

    useEffect(() => {

        if (hotjar.initialized()) {
            hotjar.stateChange('/residencyupdate');
        }

        residancyUpdateOnLoad();
    }, []);

    const residancyUpdateOnLoad = () => {


        FlowSRCAPI.ResidencyCheck(data).then((response) => {

            let checkResidencyStatus = response.responseStatus;
            let residancyCountry = '';
            let residancyCountryOther = '';
            let residancyState = '';

            if (response.country === '' && response.state === '' && response.countryOther === '') {
                setLoading(false);
                return;
            }


            if (response.country.toLowerCase() === 'united states') {
                residancyCountry = response.country;
                residancyState = response.state;
                residancyCountryOther = '';
            } else if (response.country.toLowerCase() === 'other') {
                residancyState = '';
            } else {
                residancyCountryOther = '';
                residancyState = '';
            }

            setData(values => {
                return {
                    ...values,
                    country: residancyCountry,
                    countryOther: residancyCountryOther,
                    state: residancyState
                }
            });

            switch (checkResidencyStatus.toLowerCase()) {
                case 'success':
                    navigate('/affirmationupdate', { props: props })
                    break;
                case 'bad country':
                    toast.error('Based on your residency, the Marketplace is not available to you at this time.');
                    break;
                case 'bad state':
                    toast.error('Based on your residency, the Marketplace is not available to you at this time.');
                    break;
                default:
                    toast.error(checkResidencyStatus);
            }
            setLoading(false);
        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0)
            }
            else
                ShowMarketplaceError(e.message, 'residancyupdate.js', 'residancyUpdateOnLoad()');
            setLoading(false);

        });
    };


    const handleInputChange = (e) => {
        e.preventDefault();
        setData(prevState => {
            return { ...prevState, [e.target.name]: e.target.value }
        })
    };


    const residancyUpdateClick = (event) => {

        event.preventDefault();

        setLock(true);

        FlowSRCAPI.ResidencyUpdate(data).then((response) => {

            let checkResidencyStatus = response.responseStatus;

            switch (checkResidencyStatus.toLowerCase()) {
                case 'success':
                    navigate('/affirmationupdate', { props: props })
                    toast.success('Residency update successfully.');
                    break;
                case 'bad country':
                    toast.error('Based on your residency, the Marketplace is not available to you at this time.');
                    break;
                case 'bad state':
                    toast.error('Based on your residency, the Marketplace is not available to you at this time.');
                    break;
                default:
                    toast.error(checkResidencyStatus);
            }

            setLock(false);

        }).catch((e) => {
            setLock(false);
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0)
            }
            else {
                ShowMarketplaceError(e.message, 'process.js', 'ProcessFormPost');
            }
        });
    };

    return (
        <>
            {!loading ?
                <div className='marketplaceFormContainer'>
                    <form className='marketplaceForm' style={data.country === 'United States' || data.country === 'Other' ? { height: '420px' } : { height: '320px' }}>
                        <div className='marketplaceFormContent'>
                            <h3 className='marketplaceFormTitle'>NexRep Contractor Marketplace</h3>
                            <p className='marketplaceFormSubtitle'>
                                Marketplace opportunities have residency requirements. Please provide the following information.
                            </p>
                            <div className='form-group'>
                                <label className='marketplaceFormFieldLabel'>Country</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <Select
                                    required
                                    fullWidth
                                    name='country'
                                    className='mt-1'
                                    value={data.country}
                                    onChange={(e) => { handleInputChange(e) }}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 400,
                                        },
                                    }}
                                    size='small'>
                                    <MenuItem selected value=''></MenuItem>
                                    <MenuItem value='United States'>United States</MenuItem>
                                    <MenuItem value='Other'>Other</MenuItem>
                                </Select>
                            </div>
                            <div className='form-group' hidden={data.country === 'United States' || data.country === '' ? true : false}>
                                <label className='marketplaceFormFieldLabel'>If other ...</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <TextField
                                    name='counteryOther'
                                    className='form-control mt-1'
                                    value={data.countryOther}
                                    onChange={(e) => { handleInputChange(e) }}
                                    size='small'
                                />
                            </div>
                            <div className='form-group' hidden={data.country === 'United States' ? false : true}>
                                <label className='marketplaceFormFieldLabel'>State</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <Select
                                    required
                                    fullWidth
                                    name='state'
                                    className='mt-1'
                                    value={data.state}
                                    onChange={(e) => { handleInputChange(e) }}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 400,
                                        },
                                    }}
                                    size='small'>
                                    <MenuItem selected value=''></MenuItem>
                                    <MenuItem value='AK'>AK</MenuItem>
                                    <MenuItem value='AL'>AL</MenuItem>
                                    <MenuItem value='AR'>AR</MenuItem>
                                    <MenuItem value='AZ'>AZ</MenuItem>
                                    <MenuItem value='CA'>CA</MenuItem>
                                    <MenuItem value='CO'>CO</MenuItem>
                                    <MenuItem value='CT'>CT</MenuItem>
                                    <MenuItem value='DC'>DC</MenuItem>
                                    <MenuItem value='DE'>DE</MenuItem>
                                    <MenuItem value='FL'>FL</MenuItem>
                                    <MenuItem value='GA'>GA</MenuItem>
                                    <MenuItem value='HI'>HI</MenuItem>
                                    <MenuItem value='IA'>IA</MenuItem>
                                    <MenuItem value='ID'>ID</MenuItem>
                                    <MenuItem value='IL'>IL</MenuItem>
                                    <MenuItem value='IN'>IN</MenuItem>
                                    <MenuItem value='KS'>KS</MenuItem>
                                    <MenuItem value='KY'>KY</MenuItem>
                                    <MenuItem value='LA'>LA</MenuItem>
                                    <MenuItem value='MA'>MA</MenuItem>
                                    <MenuItem value='MD'>MD</MenuItem>
                                    <MenuItem value='ME'>ME</MenuItem>
                                    <MenuItem value='MI'>MI</MenuItem>
                                    <MenuItem value='MN'>MN</MenuItem>
                                    <MenuItem value='MO'>MO</MenuItem>
                                    <MenuItem value='MS'>MS</MenuItem>
                                    <MenuItem value='MT'>MT</MenuItem>
                                    <MenuItem value='NC'>NC</MenuItem>
                                    <MenuItem value='ND'>ND</MenuItem>
                                    <MenuItem value='NE'>NE</MenuItem>
                                    <MenuItem value='NH'>NH</MenuItem>
                                    <MenuItem value='NJ'>NJ</MenuItem>
                                    <MenuItem value='NM'>NM</MenuItem>
                                    <MenuItem value='NV'>NV</MenuItem>
                                    <MenuItem value='NY'>NY</MenuItem>
                                    <MenuItem value='OH'>OH</MenuItem>
                                    <MenuItem value='OK'>OK</MenuItem>
                                    <MenuItem value='OR'>OR</MenuItem>
                                    <MenuItem value='PA'>PA</MenuItem>
                                    <MenuItem value='RI'>RI</MenuItem>
                                    <MenuItem value='SC'>SC</MenuItem>
                                    <MenuItem value='SD'>SD</MenuItem>
                                    <MenuItem value='TN'>TN</MenuItem>
                                    <MenuItem value='TX'>TX</MenuItem>
                                    <MenuItem value='UT'>UT</MenuItem>
                                    <MenuItem value='VA'>VA</MenuItem>
                                    <MenuItem value='VT'>VT</MenuItem>
                                    <MenuItem value='WA'>WA</MenuItem>
                                    <MenuItem value='WI'>WI</MenuItem>
                                    <MenuItem value='WV'>WV</MenuItem>
                                    <MenuItem value='WY'>WY</MenuItem>
                                </Select>
                            </div>
                            <div className='gap-2 mt-3'>
                                <button disabled={lock} type='submit' className='marketplaceButton mt-2' onClick={(e) => { residancyUpdateClick(e) }}>
                                    Next
                                </button>
                                <button disabled={lock} type='submit' className='marketplaceButton mt-2'>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                : <MarketplaceSpinner hidden={false} />
            }
        </>
    );
};

export default ResidancyUpdate;