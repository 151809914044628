import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import { ShowMarketplaceError } from '../../utils/core/coreutil';
import FlowSRCAPI from '../../api/flowsrc/flowsrcapi';
import MarketplaceSpinner from '../../components/shared/marketplacespinner/marketplacespinner';
import { AssessmentID } from '../../redux/process/processreducer';

const AssessmentUpdate = (props) => {
    const marketplaceSessionGUID = useSelector((state) => state.core.marketplaceSessionGUID);
    const agentProfileGUID = useSelector((state) => state.core.agentProfileGUID);
    const agentProfileID = useSelector((state) => state.core.agentProfileID);
    const assessmentID = useSelector((state) => state.process.assessmentID);
    const [data, setData] = useState({
        marketplaceSessionGUID: marketplaceSessionGUID,
        agentProfileGUID: agentProfileGUID,
        agentProfileID: agentProfileID,
        assessmentUpdateType: '',
        assessmentID: assessmentID,
        processID: 0
    });
    const navigate = useNavigate();
    const [inProcess, setInProcess] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        assessmentUpdateOnLoad();
    }, []);


    const assessmentUpdateOnLoad = () => {

        FlowSRCAPI.AssessmentCheck(data).then((response) => {

            let checkAssessmentStatus = response.responseStatus;

            switch (checkAssessmentStatus.toLowerCase()) {
                case 'new':
                    setData(values => {
                        return {
                            ...values,
                            assessmentUpdateType: "New",
                            assessmentID: response.assessmentID,
                            processID: response.processID
                        }
                    });
                    break;
                case 'in process':
                    setInProcess(true);
                    setData(values => {
                        return {
                            ...values,
                            assessmentUpdateType: "In Process",
                            assessmentID: response.assessmentID,
                            processID: response.processID
                        }
                    });
                    break;
                case 'complete':
                    setData(values => {
                        return {
                            ...values,
                            assessmentUpdateType: "Complete"
                        }
                    });
                    navigate('/gateway', { props: props });
                    break;
                default:
                    toast.error(checkAssessmentStatus);
            }
            setLoading(false);
        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0)
            }
            else
                ShowMarketplaceError(e.message, 'assessmentupdate.js', 'assessmentUpdateOnLoad()');
        });
    };

    const onNextClick = () => {

        if (data.assessmentUpdateType.toLowerCase() === ' complete')
            navigate('/gateway', { props: props });
        else
            navigate('/assessment', { state: { assessmentID: data.assessmentID, processID: data.processID } });

    };

    return (
        <>
            {!loading ?
                <div className='marketplaceFormContainer'>
                    <form className='marketplaceForm' style={{ height: '450px' }}>
                        <div className='marketplaceFormContent'>
                            <h3 className='marketplaceFormTitle'>NexRep Contractor Marketplace</h3>
                            <p hidden={inProcess}>
                                Over the next 30 to 40 minutes, you’ll complete a series of steps that will introduce you to the Marketplace and help match you with the most relevant opportunities.
                                After you've completed all steps, if matches are found, you'll be able to schedule a call with a Marketplace Coordinator to discuss these opportunities and the final steps you’ll take to become an independent contractor in the NexRep Contractor Marketplace.
                            </p>
                            <p hidden={!inProcess}>It looks like you have a few steps remaining. Completing these steps will introduce you to the Marketplace and help match you with the most relevant opportunities. Marketplace Coordinators are eager to discuss any matches and help get you on the road to becoming an independent contractor in the NexRep Contractor Marketplace.</p>
                            <p>
                                <b>Please Note</b>
                            </p>
                            <ul>
                                <li>Once you have completed a section, you will <b>not</b> be able to go back or edit your answers.</li>
                                <li>If you leave part of the way through, you can return later and pick up where you left off.</li>
                            </ul>
                            <p>
                                To proceed, click the "Next" button below and have fun!
                            </p>
                            <div className='gap-2 mt-3'>
                                <button type='submit' className='marketplaceButton mt-2' onClick={onNextClick} >
                                    Next
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                : <MarketplaceSpinner hidden={false} />
            }
        </>
    );
};

export default AssessmentUpdate;
