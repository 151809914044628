import PropTypes from 'prop-types';

const FormImage = (props) => {

    const { step } = props;

    return (
        <>
            {step.stepDetails.MediaType === 'URL' ?
                <img alt='' height={step.stepDetails.MediaHeight} className='markeplaceProcessImage mt-2' width='100%' src={step.stepDetails.MediaSource} />
                :
                <img alt='' type={step.stepDetails.MediaType} className='markeplaceProcessImage mt-2' height={step.stepDetails.MediaHeight} width='100%' src={'data:' + step.stepDetails.MediaType + ';base64,' + step.stepDetails.MediaSource} />
            }
        </>
    );

};

export default FormImage;

FormImage.propTypes = {
    step: PropTypes.any.isRequired,
};
