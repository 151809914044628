import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';

import { ShowMarketplaceError } from '../../utils/core/coreutil';
import FlowSRCAPI from '../../api/flowsrc/flowsrcapi';

const ProfilePassword = (props) => {

    const { data, setData } = props;
    const navigate = useNavigate();
    const [lock, setLock] = useState(false);

    if (data.email === '')
        navigate('/login');


    const handleInputChange = (e) => {
        e.preventDefault();
        setData(prevState => {
            return { ...prevState, [e.target.name]: e.target.value }
        })
    };

    const setPassword = (event) => {

        event.preventDefault();

        if (data.email === '' || data.password === '' || data.confirmpassword === '') {
            toast.error('All fields required.');
            return;
        }

        if (data.password !== data.confirmpassword) {
            toast.error('Passwords do not match.');
            return;
        }

        setLock(true);

        FlowSRCAPI.ProfileNewSetPassword(data).then((response) => {

            let newPasswordStatus = response.responseStatus;
            switch (newPasswordStatus.toLowerCase()) {
                case 'password set':
                    navigate('/login', { props: data.email });
                    break;
                case 'failed: profile not found.':
                    toast.error(newPasswordStatus);
                    break;
                case 'failed: registration already started.':
                    toast.error(newPasswordStatus);
                    break;
                default:
                    toast.error(newPasswordStatus);
            }

            setLock(false);

        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0)
            }
            else
                ShowMarketplaceError(e.message, 'ProfilePassword.js', 'ProfilePassword()');
            setLock(false);
        });
    };

    return (
        <div>
            <p className='marketplaceFormSubtitle'>
                Please check your email address and create a password for your new Marketplace account..
            </p>
            <div className='form-group'>
                <label className='marketplaceFormFieldLabel'>Email</label>
                <span className="marketplaceFormFieldLabelRequired"> *</span>
                <TextField
                    name='email'
                    className='form-control mt-1'
                    value={data.email}
                    onChange={(e) => { handleInputChange(e) }}
                    size='small'
                />
            </div>
            <div className='form-group'>
                <label className='marketplaceFormFieldLabel'>Password</label>
                <span className="marketplaceFormFieldLabelRequired"> *</span>
                <TextField
                    name='password'
                    type='password'
                    className='form-control mt-1'
                    value={data.password}
                    onChange={(e) => { handleInputChange(e) }}
                    size='small'
                />
            </div>
            <div className='form-group'>
                <label className='marketplaceFormFieldLabel'>Confirm Password</label>
                <span className="marketplaceFormFieldLabelRequired"> *</span>
                <TextField
                    name='confirmpassword'
                    type='password'
                    className='form-control mt-1'
                    value={data.confirmpassword}
                    onChange={(e) => { handleInputChange(e) }}
                    size='small'
                />
            </div>
            <div className='gap-2 mt-3'>
                <button disabled={lock} type='submit' className='marketplaceButton mt-2' onClick={(e) => { setPassword(e) }}>
                    Next
                </button>
                <div className='marketplaceFormFooter'>
                    <label className='text-right mt-3'>
                        Already a member? <Link className='marketplaceFormLink' to="/login">Sign In</Link>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ProfilePassword;

ProfilePassword.propTypes = {
    data: PropTypes.any,
    setData: PropTypes.func
};