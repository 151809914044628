import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ButtonBase, Divider, List, ListItemButton, ListItemText, Paper } from '@mui/material';
import { BrowserView, MobileView } from 'react-device-detect';
import { Accordion, AccordionSummary, AccordionDetails, Radio, RadioGroup } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { ShowMarketplaceError, ShowMarketplaceMessage } from '../../../utils/core/coreutil';
import { Controller } from 'react-hook-form';

const FormCalendar = (props) => {

    const { stepDetails, control } = props;
    const [selectedDays, setSelectedDays] = useState(stepDetails.fieldValue);
    const [count, setCount] = useState(0);
    const [timeSlots, setTimeSlots] = useState([]);
    const [daysOfWeek, setDaysOfWeek] = useState([]);

    useEffect(() => {
        rotateDays();
        getTimeSlots();
    }, [])

    useEffect(() => {
    }, [timeSlots])

    function rotateDays() {
        let weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let startDayIndex = 7 - weekDays.indexOf(stepDetails.calendarStartDay);
        for (let i = 0; i < startDayIndex; i++) {
            weekDays.unshift(weekDays.pop());
        }
        setDaysOfWeek(weekDays);
    };

    function getTimeSlots() {

        let minutesInDay = 1440;
        let timeSlotArr = [];

        for (let i = 0; i <= minutesInDay - 60; i += 60) {

            let halfHourInLoop = i / 60;
            let formattedHourSlot = String(halfHourInLoop);
            let hour = formattedHourSlot.split('.')[0];
            let formattedMinuteSlot = formattedHourSlot.split('.')[1] ? Number(formattedHourSlot.split('.')[1]) : 0;
            let minute = '';

            if (formattedMinuteSlot === 0)
                minute = '00';
            else if (formattedMinuteSlot === 25)
                minute = '15';
            else if (formattedMinuteSlot === 5)
                minute = '30';
            else if (formattedMinuteSlot === 75)
                minute = '45';

            formattedHourSlot = `${hour}:${minute}`;

            let today = new Date();

            let timeString = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                Number(hour),
                Number(minute),
            );

            let timeSlot = timeString.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

            timeSlotArr.push({
                timeSlot: timeSlot,
                timeValue: 60 / 60,
            });
        }

        setTimeSlots(timeSlotArr);
    };

    function timeSlotOnClick(e, timeSlot) {
        try {

            let time = timeSlot.split('~|~')[1];
            let selectedTimeSlotValue = timeSlots.find(x => x.timeSlot === time).timeValue;
            if (e.target.classList.contains('selected')) {
                e.target.classList.remove('selected');
                let currentDays = [...selectedDays];
                currentDays.splice(currentDays.indexOf(timeSlot), 1);
                setSelectedDays(currentDays);
                setCount(count > 0 ? count - selectedTimeSlotValue : selectedTimeSlotValue);
            }
            else {
                if (count === Number(stepDetails.calendarMaxInterval)) {
                    ShowMarketplaceMessage('error', 'You have reached the maximum number of intervals allowed.');
                    return;
                }
                else {
                    e.target.classList.add('selected');
                    setSelectedDays(previousDays => [...previousDays, timeSlot]);
                    setCount(count > 0 ? count + selectedTimeSlotValue : selectedTimeSlotValue);
                }
            }
        }
        catch (e) {
            ShowMarketplaceError(e.message, 'formcalendar.js', 'timeSlotOnClick()', e.stack)
        }
    };


    return (
        <>
            <label className={stepDetails.promptLabelClass + ' ' + (stepDetails.isFlex && stepDetails.promptLabelColumns && stepDetails.promptLabelColumns !== '' ? ' col-' + stepDetails.promptLabelColumns : '')}>{stepDetails.promptLabel}<span className='marketplaceProcessRequired'>{stepDetails.validateRequired && ' *'}</span></label>
            <Controller
                key={stepDetails.referenceValidate}
                control={control}
                name={stepDetails.referenceValidate}
                defaultValue={stepDetails.fieldValue}
                render={({ field, fieldState: { error } }) => (
                    <>
                        <BrowserView>
                            {
                                stepDetails.calenderShowCounter === 'Yes' ?
                                    <div className='marketplaceCalendarCounter'>
                                        <div className='title'>
                                            Hours Selected
                                        </div>
                                        <div id={'marketplaceCalendarCounter_' + stepDetails.referenceJquery} class='count'>
                                            {count}
                                        </div>
                                    </div>
                                    : null
                            }
                            <div id={'marketplaceCalendar_' + stepDetails.reference}>
                                <table id={'marketplaceCalendarTable_' + stepDetails.referenceJquery} className='marketplaceCalendarTable'>
                                    <thead className='marketplaceBackgroundGrey marketplaceFontBold'>
                                        {
                                            daysOfWeek.map((day) => {
                                                return <th>{day}</th>
                                            })
                                        }
                                    </thead>
                                    <tbody>
                                        {
                                            timeSlots.length > 0 && timeSlots.map((timeSlot) => (
                                                <tr>
                                                    {
                                                        daysOfWeek.map((day) => (
                                                            <td onClick={(e) => timeSlotOnClick(e, day + '~|~' + timeSlot.timeSlot)}>
                                                                <ButtonBase component="div" sx={{ height: '100%', width: '100%', borderRadius: '4px' }}>
                                                                    {timeSlot.timeSlot}
                                                                </ButtonBase>
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </BrowserView>
                        <MobileView>
                            {
                                stepDetails.calenderShowCounter === 'Yes' ?
                                    <Paper elevation={2} className='marketplaceCalendarCounterMobile' sx={{ height: '45px', borderRadius: '8px' }}>
                                        <div className='title'>
                                            Hours Selected
                                        </div>
                                        <div id={'marketplaceCalendarCounter_' + stepDetails.referenceJquery} class='count'>
                                            {count}
                                        </div>
                                    </Paper>
                                    : null
                            }
                            {
                                daysOfWeek.map((day) => {
                                    return (
                                        <Accordion
                                            elevation={2}
                                            sx={{
                                                borderRadius: '8px',
                                                marginBottom: '5px',
                                                '&:before': {
                                                    display: 'none',
                                                }
                                            }}>
                                            <AccordionSummary expandIcon={<ExpandMore fontSize='large' style={{ paddingLeft: '5px' }} />}>
                                                <div >
                                                    {day}
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <List component='div'>
                                                    <Divider />
                                                    {
                                                        timeSlots.length > 0 && timeSlots.map((timeSlot) => (
                                                            <ListItemButton
                                                                onClick={(e) => timeSlotOnClick(e, day + '~|~' + timeSlot.timeSlot)}
                                                                divider
                                                                sx={selectedDays.indexOf(day + '~|~' + timeSlot.timeSlot) !== -1 ? { backgroundColor: 'yellow' } : null}>
                                                                <ListItemText primary={timeSlot.timeSlot} />
                                                            </ListItemButton>
                                                        ))
                                                    }

                                                </List>
                                            </AccordionDetails>
                                        </Accordion>

                                    )
                                })
                            }
                        </MobileView>

                        {error && error?.type === 'required' && (
                            <label className='marketplaceProcessRequired'>This field is required.</label>
                        )}
                        <input type='hidden' {...field} className='form-control' name={stepDetails.reference} id={stepDetails.reference} value={selectedDays}></input>
                    </>
                )}
                rules={{
                    required: stepDetails.validateRequired
                }}
            />
        </>
    );
};

export default FormCalendar;

FormCalendar.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    control: PropTypes.any
};
