import axios from "axios";

import { SOCRATESAPIURL } from "../../config/constant";


const ClassAPI = ({

    ClassAvailability: function (data) {

        try {

            return axios({
                url: `${SOCRATESAPIURL}/SocratesMarketplace/ClassAvailability`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    ClassConfirm: function (data, programClassID) {

        try {

            return axios({
                url: `${SOCRATESAPIURL}/SocratesMarketplace/ClassConfirm`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID,

                    'programClassID': programClassID,
                    'firstName': data.firstName,
                    'lastName': data.lastName,
                    'address1': data.address1,
                    'address2': data.address2,
                    'city': data.city,
                    'state': data.state,
                    'zip': data.zip,
                    'homePhone': data.homePhone,
                    'cellPhone': data.cellPhone,
                    'smsOptIn': data.smsOptIn,
                    'email': data.email,
                    'education': data.education,
                    'languageFluency': data.languageFluency,
                    'contactCenterPhoneSupport': data.contactCenterPhoneSupport === true ? 'Yes' : 'No',
                    'contactCenterChatSupport': data.contactCenterChatSupport ? 'Yes' : 'No',
                    'contactCenterEmailSupport': data.contactCenterEmailSupport ? 'Yes' : 'No'
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    ScheduleCancel: function (data) {

        try {

            return axios({
                url: `${SOCRATESAPIURL}/SocratesMarketplace/ClassConfirm`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID,
                    'existingSourcingScheduleID': data.existingSourcingScheduleID,
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
});

export default ClassAPI;