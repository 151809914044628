import PropTypes from 'prop-types';
import $ from 'jquery';
import { Button, FormControl } from '@mui/material';
import { toast } from 'react-toastify';

import { ShowMarketplaceError } from '../../../utils/core/coreutil';
import ProcessAPI from '../../../api/process/processapi';
import { Controller } from 'react-hook-form';

const FormVideoRecorder = (props) => {

    const { processSessionGUID, stepDetails, control } = props;

    let referenceTrim = stepDetails.reference.replaceAll('~', '');

    let webCamContainer = document.getElementById('videoPlayer_' + referenceTrim);

    let videoMediaConstraints = {
        audio: true,
        video: true,
    };
    let chunks = [];
    let mediaRecorder = null;
    let streamMedia = null;
    let mediaURL = null;

    const startRecording = (thisButton, otherButton) => {
        $('#uploadRecording_' + referenceTrim).css('visibility', 'hidden');
        navigator.mediaDevices.getUserMedia(videoMediaConstraints).then((mediaStream) => {
            mediaRecorder = new MediaRecorder(mediaStream);
            streamMedia = mediaStream;
            mediaRecorder.start();
            mediaRecorder.ondataavailable = (e) => {
                chunks.push(e.data);
            };
            mediaRecorder.onstop = () => {
                let blob = new Blob(
                    chunks, {
                    type: 'video/mp4'
                });
                chunks = [];
                let recordedMediaURL = URL.createObjectURL(blob);
                let recordedMedia = document.getElementById('videoPlayer_' + referenceTrim);
                recordedMedia.src = recordedMediaURL;
                webCamContainer.muted = false;
                mediaURL = blob;

                if (mediaURL) {
                    let file = new File([mediaURL], 'recording' + + stepDetails.field + + '.wav');
                    if (file) {
                        ProcessAPI.Upload(processSessionGUID, file, stepDetails.validateFileSizeMax).then((response) => {
                            if (response.validProcessSession !== 'Yes') {
                                toast.error('There was a issue uploading the recording, please try again.');
                            }

                            let fileName = file.name;
                            document.getElementById(stepDetails.reference).value = fileName;

                        }).catch((e) => {
                            ShowMarketplaceError(e.message, 'process.js', 'uploadRecording()');
                        });
                    }
                    $('#uploadRecording_' + referenceTrim).css('visibility', 'hidden');
                    $('#audioRecordStatus').html('File Uploaded.');
                };
            };
            $('#videoRecordStatus').html('Recording');
            $('#videoRecordStatus').css('color', 'red');
            $('#videoRecordStatus').addClass('marketplaceProcessMediaRecordingInProgress');
            webCamContainer.srcObject = mediaStream;
            webCamContainer.autoplay = true;
            webCamContainer.muted = true;
            thisButton.disabled = true;
            otherButton.disabled = false;
        }).catch((err) => {
            $('#videoRecordStatus').html('You have denied access to your microphone and camera, please enable microphone and camera access before trying to record video.');
            $('#videoRecordStatus').css('color', 'red');
            thisButton.disabled = false;
            otherButton.disabled = false;
        });
    }

    const stopRecording = (thisButton, otherButton) => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            streamMedia.getTracks()
                .forEach((track) => {
                    track.stop();
                });
            $('#videoRecordStatus').html('Recording Finished');
            $('#videoRecordStatus').css('color', 'black');
            $('#videoRecordStatus').removeClass('marketplaceProcessMediaRecordingInProgress');

            webCamContainer.srcObject = null;
            webCamContainer.autoplay = false;

            if (stepDetails.mediaRetake && stepDetails.mediaRetake === 'No') {
                thisButton.disabled = true;
                otherButton.disabled = true;
                $('#uploadRecording_' + referenceTrim).trigger('click');

            }
            else {
                thisButton.disabled = true;
                otherButton.disabled = false;
                $('#uploadRecording_' + referenceTrim).css('visibility', 'visible');
            }
        }
    }

    return (
        <FormControl fullWidth>
            <label className={[stepDetails.promptLabelClass, (stepDetails.isFlex && stepDetails.promptLabelColumns && stepDetails.promptLabelColumns !== '' ? ' col-' + stepDetails.promptLabelColumns : '')]} >{stepDetails.promptLabel}<span className='marketplaceProcessRequired'>{stepDetails.validateRequired && ' *'}</span></label>
            <Controller
                key={stepDetails.referenceValidate}
                control={control}
                name={stepDetails.referenceValidate}
                defaultValue={stepDetails.fieldValue}
                render={({ field, fieldState: { error } }) => (
                    <>
                        <div className='marketplaceProcessMediaRecorder'>
                            <label id='videoRecordStatus' className='marketplaceProcessMediaRecorderStatus'>Click the 'Start Recording' button to start recording</label>
                            <video id={'videoPlayer_' + referenceTrim} className='marketplaceProcessVideo mt-2' style={{ width: '100%' }} controls controlsList='nofullscreen nodownload'></video >
                            <Button size='small' variant='contained' className='btn btn-default marketplaceProcessButton' id={'startRecording_' + referenceTrim} onClick={(e) => { startRecording(document.getElementById(e.target.id), document.getElementById('stopRecording_' + referenceTrim)) }}> Start recording</Button>
                            <Button size='small' variant='contained' className='btn btn-default marketplaceProcessButton' id={'stopRecording_' + referenceTrim} onClick={(e) => { stopRecording(document.getElementById(e.target.id), document.getElementById('startRecording_' + referenceTrim)) }}> Stop recording</Button>
                            <input {...field} type='text' readOnly name={stepDetails.reference} id={referenceTrim} className='form-control btn btn-default' style={{ visibility: 'hidden' }} />
                        </div >
                        {error && error?.type === 'required' && (
                            <label className='marketplaceProcessRequired'>This field is required.</label>
                        )}
                    </>
                )}
                rules={{
                    required: stepDetails.validateRequired
                }}
            />
        </FormControl>
    );

};

export default FormVideoRecorder;

FormVideoRecorder.propTypes = {
    processSessionGUID: PropTypes.string,
    stepDetails: PropTypes.any.isRequired,
    control: PropTypes.any
};
