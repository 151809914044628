import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hotjar } from 'react-hotjar';

import ProfileNew from '../flowsrc/profilenew';
import ProfileExisting from '../flowsrc/profileexisting';
import ProfilePassword from '../flowsrc/profilepassword';
import MarketplaceSpinner from '../../components/shared/marketplacespinner/marketplacespinner';

const Login = ({ props }) => {
    const marketplaceSessionGUID = useSelector((state) => state.core.marketplaceSessionGUID);
    const isAuthenticated = useSelector((state) => state.core.isAuthenticated);
    const userSessionGUID = useSelector((state) => state.core.userSessionGUID);
    const userSessionID = useSelector((state) => state.core.userSessionID);
    const promptSources = useSelector((state) => state.core.promptSources);
    const [data, setData] = useState({
        marketplaceSessionGUID: marketplaceSessionGUID,
        userSessionGUID: userSessionGUID,
        userSessionID: userSessionID,
        email: '',
        password: '',
        confirmpassword: '',
        firstname: '',
        lastname: '',
        webTrafficSourceDetailID: 0,
        webTrafficSourceOther: '',
        promptSources: promptSources
    });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        setData(prevState => {
            return {
                ...prevState,
                marketplaceSessionGUID: marketplaceSessionGUID,
                userSessionGUID: userSessionGUID,
                userSessionID: userSessionID
            }
        })

        if (isAuthenticated & isAuthenticated !== undefined) {
            navigate('/residancyupdate', { props: props })
        }

        setLoading(false);

    }, []);

    function getFormSize() {

        if (location.pathname === '/login')
            return { height: '490px' }
        else if (location.pathname === '/password')
            return { height: '440px' }
        else if (location.pathname === '/register')
            return { height: '580px' }
    }

    return (
        <>{
            loading ? <MarketplaceSpinner />
                :
                <div className='marketplaceFormContainer'>
                    <form className='marketplaceForm' style={getFormSize()}>
                        <div className='marketplaceFormContent'>
                            {(() => {
                                if (location.pathname === '/login') {
                                    return (
                                        <ProfileExisting setData={setData} data={data} />
                                    )
                                }
                                else if (location.pathname === '/password') {
                                    return (
                                        <ProfilePassword setData={setData} data={data} />
                                    )
                                }
                                else if (location.pathname === '/register') {
                                    return (
                                        <ProfileNew setData={setData} data={data} />
                                    )
                                }
                            })()}
                        </div>
                    </form>
                </div>
        }
        </>
    );

};

export default Login;