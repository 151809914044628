
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { Controller } from 'react-hook-form';

const FormRadio = (props) => {

    const { stepDetails, control } = props;
    const isChecked = (source) => {
        if (source?.Value?.toLowerCase() === stepDetails?.fieldValue?.toLowerCase() || source?.Selected?.toLowerCase() === 'yes')
            return true;
    };
    return (
        <>
            {
                stepDetails.promptLabel ?
                    <label className={stepDetails.promptLabelClass + ' ' + (stepDetails.isFlex && stepDetails.promptLabelColumns && stepDetails.promptLabelColumns !== '' ? ' col-' + stepDetails.promptLabelColumns : '')} >{stepDetails.promptLabel}<span className='marketplaceProcessRequired'>{stepDetails.validateRequired && ' *'}</span></label>
                    : null
            }
            <Controller
                key={stepDetails.referenceValidate}
                control={control}
                name={stepDetails.referenceValidate}
                defaultValue={stepDetails.fieldValue}
                render={({ field, fieldState: { error } }) => (
                    <>
                        {stepDetails.selectValues.map((source, index) => (
                            <div key={index} className='flex-radio'>
                                <div key={index} className={'form-check marketplaceProcessRadioButtonFieldSet ' + (stepDetails.isFlex && stepDetails.promptValueColumns ? ' col-' + stepDetails.promptValueColumns : '')}>
                                    <input type='radio' name={stepDetails.reference} key={index}  {...field} checked={isChecked(source)} value={source.Value} />
                                    <label dangerouslySetInnerHTML={{ __html: sanitize(source.Label) }}></label>
                                </div>
                            </div>
                        ))}
                        <div className={stepDetails.isFlex ? 'flex-label' : ''} style={{ marginTop: '25px' }}>
                            {error && error?.type === 'required' && (
                                <label className={'marketplaceProcessRequired ' + (stepDetails.isFlex ? 'flex-label' : '')}>This field is required.</label>
                            )}
                        </div>

                    </>
                )}
                rules={{
                    required: stepDetails.validateRequired,
                }}
            />
        </>
    );
};

export default FormRadio;

FormRadio.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    control: PropTypes.any
};
