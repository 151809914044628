import { useEffect, useState } from 'react';
import moment from 'moment';

export function FormatDate(date, format) {

    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var milliseconds = date.getMilliseconds();


    if (!format) {
        format = 'MM/dd/yyyy hh:mm:ss.ff t';
    }

    if (format.indexOf('MM') > -1) {
        format = format.replace('MM', (month < 10 ? month = '0' + month.toString() : month.toString()));
    }

    if (format.indexOf('dd') > -1) {
        format = format.replace('dd', (day < 10 ? day = '0' + day.toString() : day.toString()));
    }

    if (format.indexOf('yyyy') > -1) {
        format = format.replace('yyyy', year.toString());
    } else if (format.indexOf('yy') > -1) {
        format = format.replace('yy', year.toString().substr(2, 2));
    }

    if (format.indexOf('HH') > -1) {
        format = format.replace('HH', (hours < 10 ? hours = '0' + hours.toString() : hours.toString()));
    }

    if (format.indexOf('hh') > -1) {
        hours = (hours > 12) ? hours -= 12 : hours;
        hours = (hours === 0) ? hours = 12 : hours;
        format = format.replace('hh', (hours < 10 ? hours = '0' + hours.toString() : hours.toString()));
    }

    if (format.indexOf('mm') > -1) {
        format = format.replace('mm', (minutes < 10 ? minutes = '0' + minutes.toString() : minutes.toString()));
    }

    if (format.indexOf('ss') > -1) {
        format = format.replace('ss', (seconds < 10 ? seconds = '0' + seconds.toString() : seconds.toString()));
    }

    if (format.indexOf('ff') > -1) {
        format = format.replace('ff', (milliseconds < 10 ? milliseconds = '0' + milliseconds.toString() : milliseconds.toString()).substr(0, 2));
    }

    if (format.indexOf('t') > -1) {
        if (hours > 11) {
            format = format.replace('t', 'PM');
        } else {
            format = format.replace('t', 'AM');
        }
    }

    return format;

};

export function FormatScheduleDate(date, digits) {
    let year = date.getFullYear().toString();

    if (digits === '2')
        year = year.substring(2)

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
};

export function FormatTime(time) {

    time = time.split(':');

    var hours = Number(time[0]);
    var minutes = Number(time[1]);

    var timeValue;

    if (hours > 0 && hours <= 12) {
        timeValue = '' + hours;
    } else if (hours > 12) {
        timeValue = '' + (hours - 12);
    } else if (hours == 0) {
        timeValue = '12';
    }

    timeValue += (minutes < 10) ? ':0' + minutes : ':' + minutes;
    timeValue += (hours >= 12) ? ' PM' : ' AM';

    return timeValue;

};

export function FormatMessageDate(date) {

    var returnDate = new Date(date);
    var today = new Date();

    if (returnDate.getDate() === today.getDate()) {
        var hours = returnDate.getHours();
        var minutes = returnDate.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    else if (returnDate > today - (1000 * 60 * 60 * 24 * 7)) {
        return GetDay(returnDate.getDay());
    }
    else if (returnDate < today - (1000 * 60 * 60 * 24 * 7)) {
        return moment(returnDate).format('MM/DD/YY');
    }

};

export function ScriptViewActionCriteria(actionCriteria) {

    if (actionCriteria === '')
        return 'null';

    var actionSplit = actionCriteria.split('~|~');
    var actionCriteriaJSON = new Object();

    for (var x = 0; x < actionSplit.length; x++) {
        let strParts = actionSplit[x].split('=');
        if (strParts[0] && strParts[1]) {
            actionCriteriaJSON[strParts[0].replace(/\s+/g, '')] = strParts[1].trim();
        }
    }

    return actionCriteriaJSON;

};

export function ScriptPromptValues(promptValues) {

    if (promptValues === undefined)
        return null;

    var prompValuesJSON = new Object();

    for (var key of Object.keys(promptValues)) {
        prompValuesJSON[key.replace(/~/g, '.')] = promptValues[key].trim();
    }

    return prompValuesJSON;

};

export function ScriptCalendarActionCriteria(actionCriteria) {

    if (actionCriteria === '')
        return 'null';

    var actionSplit = actionCriteria.split('~|~');
    var actionCriteriaJSON = new Object();

    for (var x = 0; x < actionSplit.length; x++) {
        var strParts = actionSplit[x].split('=');
        if (strParts[0] && strParts[1]) {
            actionCriteriaJSON[strParts[0].replace(/\s+/g, '')] = strParts[1].trim();
        }
    }

    return actionCriteriaJSON;

};

export function ScriptSearchValues(promptValues) {

    if (promptValues === undefined)
        return null;

    var i = 0;
    var promptValuesJSON = [];
    var compareLabel = '';

    for (var value of promptValues.split(',')) {

        var promptValue = new Object();

        switch (value.toLowerCase()) {
            case 'eq':
                compareLabel = 'Equal To';
                break;
            case 'eqn':
                compareLabel = 'Equal To';
                break;
            case 'gt':
                compareLabel = 'Greater Than';
                break;
            case 'gte':
                compareLabel = 'Greater Than or Equal To';
                break;
            case 'lt':
                compareLabel = 'Less Than';
                break;
            case 'lte':
                compareLabel = 'Less Than or Equal To';
                break;
            case 'sw':
                compareLabel = 'Starts With';
                break;
            case 'contains':
                compareLabel = 'Contains';
                break;
            default:
                compareLabel = 'CompareType (' + compareLabel + ') not found.';
                break;
        }

        promptValue.label = compareLabel;
        promptValue.value = value;
        promptValuesJSON.splice(i, 0, promptValue);
        i++;

    }



    return promptValuesJSON;

};

export function GetRecordValue(record, fieldName) {

    var fieldValue = '';

    for (var key in record) {
        if (record.hasOwnProperty(key)) {
            if (key.toLowerCase() === fieldName.toLowerCase()) {
                fieldValue = record[key];
                break;
            }
        }
    }

    return fieldValue;

};

export function GetOpenerValue(viewCriteria, fieldName) {

    var fieldValue = '';

    for (var key in viewCriteria) {
        if (viewCriteria.hasOwnProperty(key)) {
            if (key.toLowerCase() === fieldName.toLowerCase()) {
                fieldValue = viewCriteria[key];
                break;
            }
        }
    }
};

export function IsValidHTML(html) {

    const isHTMLType1 = (str) => !(str || '').replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/ig, '').replace(/(<([^>]+)>)/ig, '').trim();

    const isHTMLType2 = (str) => /<[a-z][\s\S]*>/i.test(str);

    if (isHTMLType1(html), isHTMLType2(html))
        return true;
    else
        return false;

};

export function FormatHTML(html) {

    html = html.replace(/(?:\r\n|\r|\n)/g, '<br>');
    html = html.replace('\t', '');
    html = html.replace(/&nbsp;/g, '');
    html = html.replace('!important', '');

    return html.trim();
};

export function UseWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export function ValidateForm(data) {

    var errorMessage = '';

    if (data.firstName === '')
        errorMessage = errorMessage + ' First Name';

    if (data.lastName === '')
        errorMessage = errorMessage + ', Last Name';

    if (data.address1 === '')
        errorMessage = errorMessage + ', Address1';

    if (data.city === '')
        errorMessage = errorMessage + ', City';

    if (data.state === '')
        errorMessage = errorMessage + ', State';

    if (data.zip === '')
        errorMessage = errorMessage + ', Zip';

    if (data.homePhone === '')
        errorMessage = errorMessage + ', Home Phone';

    if (data.cellPhone === '')
        errorMessage = errorMessage + ', Cell Phone';

    if (data.smsOptIn === '')
        errorMessage = errorMessage + ', SMS Opt-in';

    if (data.email === '')
        errorMessage = errorMessage + ', Email';

    if (data.education === '')
        errorMessage = errorMessage + ', Education';

    return errorMessage;
};

function GetDay(dayInt) {

    var dayOfWeek = '';
    switch (dayInt) {
        case 0:
            dayOfWeek = 'Sunday';
            break;
        case 1:
            dayOfWeek = 'Monday';
            break;
        case 2:
            dayOfWeek = 'Tuesday';
            break;
        case 3:
            dayOfWeek = 'Wednesday';
            break;
        case 4:
            dayOfWeek = 'Thursday';
            break;
        case 5:
            dayOfWeek = 'Friday';
            break;
        case 6:
            dayOfWeek = 'Saturday';
    }

    return dayOfWeek;
};