import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';

const FormLabel = (props) => {

    const { step } = props;

    let formLabel = step.stepDetails.FormLabel;
    let formLabelClass = step.stepDetails.FormLabelClass;
    let formLabelAlignment = step.stepDetails.FormLabelAlignment;

    if (!formLabelClass || formLabelClass === '' || formLabelClass === null)
        formLabelClass = 'socratesFontExtraDarkBold';

    if (!formLabelAlignment || formLabelAlignment === 'Left' || formLabelAlignment === '' || formLabelAlignment === null)
        formLabelAlignment = 'alignFontLeft';
 

    formLabel = formLabel?.replace(/(\r\n|\r|\n)/g, '<br>');

    return (
        <div className={'form-group ' + formLabelAlignment}>
            <label className={formLabelClass} dangerouslySetInnerHTML={{ __html: sanitize(formLabel) }} />
        </div>
    );
};

export default FormLabel;

FormLabel.propTypes = {
    step: PropTypes.any.isRequired,
};
